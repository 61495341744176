import PropTypes from 'prop-types';
import React from 'react';
import DefaultCarousel from 'react-elastic-carousel';
import { CarouselWrapper } from './styled';

const breakPoints = [
  { width: 1, itemsToShow: 1, pagination: false },
  { width: 550, itemsToShow: 2, pagination: false },
];

const Carousel = ({ plans, children }) => (
  <CarouselWrapper plans={plans}>
    <DefaultCarousel
      breakPoints={breakPoints}
      itemPadding={[0, 0]}
      outerSpacing={20}
      enableSwipe
    >
      {children}
    </DefaultCarousel>
  </CarouselWrapper>
);

Carousel.defaultProps = {
  plans: false,
};

Carousel.propTypes = {
  plans: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Carousel;
