import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const sideMap = {
  default: css`
    padding: 0 200px;
    ${media.lessThan('1921px')`
    padding: 0 150px;
    `};
    ${media.lessThan('1681px')`
    padding: 0 100px;
    `};
    ${media.lessThan('1025px')`
    padding: 0 40px;
    `};
  `,
  left: css`
    padding: 0 200px 0 0;
    ${media.lessThan('1921px')`
    padding: 0 150px 0 0;
    `};
    ${media.lessThan('1681px')`
    padding: 0 100px 0 0;
    `};
  `,
  right: css`
    padding: 0 0 0 200px;
    ${media.lessThan('1921px')`
    padding: 0 0 0 150px;
    `};
    ${media.lessThan('1681px')`
    padding: 0 0 0 100px;
    `};
    ${media.lessThan('1025px')`
    padding: 0 0 0 40px;
    `};
  `,
  all: css`
    padding: 0;
  `,
};

export const Wrapper = styled.div`
  ${({ side, withoutPadding }) => !withoutPadding && sideMap[side]}
`;
