/* eslint-disable no-nested-ternary */
import { navigate } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MOBILE_CLOSE from '../../assets/icon/close.svg';
import MOBILE_MENU from '../../assets/icon/menu.svg';
import Layout from '../Layout';
import * as S from './styled';

const Header = ({ logo, navButton, navLinks, contactsPage }) => {
  const location =
    typeof window !== 'undefined' ? window.location : { pathname: '' };

  const [mobileMenuState, setMobileMenuState] = useState(false);
  const openHamburguerMenu = () => setMobileMenuState(true);
  const closeHamburguerMenu = () => setMobileMenuState(false);

  const scroll = id => {
    scrollTo(`#${id}`);
    setMobileMenuState(false);
  };

  const hamburguerMenuHasActive = mobileMenuState ? 'block' : 'none';
  const navLinksReturn = () =>
    !!navLinks &&
    navLinks.map(nav => {
      let { redirect } = nav;
      let path = '';

      if (nav.pathname === '/') {
        if (['/', '/contato', '/contato/'].includes(location?.pathname)) {
          path = `${location?.pathname}#${nav.value}`;
        } else {
          redirect = true;
          path = `/#${nav.value}`;
        }
      } else {
        path = nav.pathname;
      }

      return redirect ? (
        <S.NavLink
          onClick={() => {
            navigate(path);
          }}
        >
          {nav.label}
        </S.NavLink>
      ) : (
        <S.NavLink onClick={() => scroll(`${nav.value}`)}>
          {nav.label}
        </S.NavLink>
      );
    });
  return (
    <>
      <Layout>
        <S.WebContainer>
          <div>
            <S.Img
              src={logo}
              alt="Logo do site escrito sua marca, sendo esta we.dash by bowe"
            />
          </div>
          <S.Nav>
            {navLinksReturn()}
            {!contactsPage && (
              <S.NavButton href={navButton}>Entrar</S.NavButton>
            )}
          </S.Nav>
        </S.WebContainer>

        <S.MobileNav py={3}>
          <S.Img
            src={logo}
            alt="Logo do site escrito sua marca, sendo esta we.dash by bowe"
          />
          <S.HamburguerMenu
            onClick={openHamburguerMenu}
            src={MOBILE_MENU}
            alt="Menu hamburguer clicavel"
          />
        </S.MobileNav>
      </Layout>
      <S.MobileContainer
        display={hamburguerMenuHasActive}
        mobileState={mobileMenuState}
        position="fixed"
        width="100vw"
        height="100vh"
        zIndex="20"
        top="0"
        py={1}
      >
        <Layout>
          <S.MobileMenuNav>
            <S.Img
              invert
              src={logo}
              alt="Logo do site escrito sua marca, sendo esta we.dash by bowe"
            />
            <S.CloseHamburguerMenu
              onClick={closeHamburguerMenu}
              src={MOBILE_CLOSE}
              alt="Letra 'x' demarcando fechamento de modal"
            />
          </S.MobileMenuNav>
          <S.Nav>
            {navLinksReturn()}

            {!contactsPage && (
              <S.NavButton href={navButton}>Entrar</S.NavButton>
            )}
          </S.Nav>
        </Layout>
      </S.MobileContainer>
    </>
  );
};

Header.defaultProps = {
  logo: null,
  navButton: '',
  navLinks: [],
  contactsPage: false,
};

Header.propTypes = {
  logo: PropTypes.node,
  navButton: PropTypes.string,
  contactsPage: PropTypes.bool,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      redirect: PropTypes.bool,
    }),
  ),
};

export default Header;
