import MaterialCard from '@material-ui/core/Card';
import styled from 'styled-components';
import { defaultTheme } from '../../styles/theme';

export const Card = styled(MaterialCard)`
  max-width: 400px;
  min-width: 400px;
  border: 1px solid
    ${({ borderActive }) =>
      borderActive
        ? defaultTheme.colors.violet
        : defaultTheme.colors.transparent};
  cursor: pointer;

  &:hover {
    border-color: ${defaultTheme.colors.violet};
    transition: all 0.7s ease-in;
  }

  input {
    display: none;
  }
`;

export const Image = styled.img`
  width: ${({ dash }) => (dash ? '40px' : '70px')};
  border-radius: 10px;
`;
