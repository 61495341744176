import styled, { css } from 'styled-components';

export const CarouselWrapper = styled.div`
  width: 100%;

  @media (max-width: 2000px) {
    .rec-arrow {
      display: none !important;
    }
    .rec-slider-container {
      margin: 0;
    }
  }
  ${({ plans }) =>
    plans
      ? css`
          .rec {
            display: flex;
          }
          @media (min-width: 1125px) {
            display: none;
          }
        `
      : css`
          .rec {
            height: 400px !important;
          }
        `}
`;
