import PropTypes from 'prop-types';
import React from 'react';
import * as S from './styled';

const Typography = ({ type, children, ...props }) => {
  const typeMap = {
    h: (
      <S.Heading type={type} {...props}>
        {children}
      </S.Heading>
    ),
    p: (
      <S.Paragraph type={type} {...props}>
        {children}
      </S.Paragraph>
    ),
  };

  return typeMap[type];
};

Typography.defaultProps = {
  type: 'h',
  level: '1',
  fontFamily: 'montserrat',
  fontSize: '',
  fontWeight: '',
  lineHeight: '',
  letterSpacing: '',
  color: 'seventh',
  opacity: '',
  background: '',
  margin: '',
  textAlign: '',
  padding: '',
  flex: false,
};
Typography.propTypes = {
  type: PropTypes.string,
  level: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  lineHeight: PropTypes.string,
  letterSpacing: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
  background: PropTypes.string,
  margin: PropTypes.string,
  textAlign: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  flex: PropTypes.bool,
};

export default Typography;
