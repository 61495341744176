import scrollTo from 'gatsby-plugin-smoothscroll';
import PropTypes from 'prop-types';
import React from 'react';
import KNOW_HOW from '../../assets/img/knowHowImg.svg';
import Button from '../Button';
import Divisor from '../Divisor';
import Layout from '../Layout';
import Typography from '../Typography';
import * as S from './styled';

const KnowHowSession = ({
  bgColor,
  title,
  list,
  btnText,
  btnTextColor,
  btnTextHoverColor,
  btnBackgroundColor,
  btnBackgroundHoverColor,
  btnBorderColor,
  btnBorderHoverColor,
  contactPage,
  ...props
}) => {
  const handleScroll = () => scrollTo('#lead-form');

  return (
    <S.Container bgColor={bgColor}>
      <Layout>
        <S.Wrapper>
          <S.Image>
            <img src={KNOW_HOW} alt="alt" loading="lazy" decoding="async" />
          </S.Image>
          <S.Body>
            {title.map(element => {
              return (
                <Typography type="h" level="4" key={element}>
                  {element}
                </Typography>
              );
            })}
            <Divisor margin="20px 0" />
            <ul>
              {list.map(item => {
                return (
                  <S.List key={item}>
                    <Typography type="p" level="1">
                      {item}
                    </Typography>
                  </S.List>
                );
              })}
            </ul>
            <S.MobileImage>
              <img src={KNOW_HOW} alt="alt" loading="lazy" decoding="async" />
            </S.MobileImage>
            <S.ButtonWrapper>
              {contactPage ? (
                <Button
                  text={btnText}
                  textColor={btnTextColor}
                  textHoverColor={btnTextHoverColor}
                  backgroundColor={btnBackgroundColor}
                  backgroundHoverColor={btnBackgroundHoverColor}
                  borderColor={btnBorderColor}
                  borderHoverColor={btnBorderHoverColor}
                  margin="50px 0 0 0"
                  fullWidth
                  onClick={handleScroll}
                />
              ) : (
                <Button
                  text={btnText}
                  textColor={btnTextColor}
                  textHoverColor={btnTextHoverColor}
                  backgroundColor={btnBackgroundColor}
                  backgroundHoverColor={btnBackgroundHoverColor}
                  borderColor={btnBorderColor}
                  borderHoverColor={btnBorderHoverColor}
                  margin="50px 0 0 0"
                  fullWidth
                  {...props}
                />
              )}
            </S.ButtonWrapper>
          </S.Body>
        </S.Wrapper>
      </Layout>
    </S.Container>
  );
};

KnowHowSession.defaultProps = {
  bgColor: 'eighth',
  title: [],
  list: [],
  btnText: 'Your text here',
  btnTextColor: 'secondary',
  btnTextHoverColor: 'secondary',
  btnBackgroundColor: 'transparent',
  btnBackgroundHoverColor: 'transparent',
  btnBorderColor: 'white',
  btnBorderHoverColor: 'white',
  contactPage: false,
};

KnowHowSession.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.arrayOf(PropTypes.string),
  list: PropTypes.arrayOf(PropTypes.string),
  btnText: PropTypes.string,
  btnTextColor: PropTypes.string,
  btnTextHoverColor: PropTypes.string,
  btnBackgroundColor: PropTypes.string,
  btnBackgroundHoverColor: PropTypes.string,
  btnBorderColor: PropTypes.string,
  btnBorderHoverColor: PropTypes.string,
  contactPage: PropTypes.bool,
};

export default KnowHowSession;
