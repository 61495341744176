import styled from 'styled-components';
import media from 'styled-media-query';
import { defaultTheme } from '../../styles/theme';

export const Container = styled.section`
  background-color: ${({ bgColor }) => defaultTheme.colors[bgColor]};
  ${media.lessThan('1281px')`
    padding-top: 50px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;

  @media (max-width: 1024px) {
    justify-content: space-evenly;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const Image = styled.div`
  width: 60%;
  padding-right: 100px;

  @media (max-width: 1440px) {
    padding-right: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 40px;
  }

  img {
    max-width: 600px;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;

export const Body = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1921px) {
    width: 50%;
  }
  @media (max-width: 1681px) {
    width: 55%;
  }
  @media (max-width: 1441px) {
    width: 65%;
  }
  @media (max-width: 1367px) {
    width: 60%;
  }
  @media (max-width: 1281px) {
    width: 100%;
  }
`;

export const List = styled.ol`
  padding: 10px 0;
  list-style: none;
  counter-reset: my-awesome-counter;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;

  li {
    counter-increment: my-awesome-counter;
    margin: 15px 0 15px 20px;
  }
  li::before {
    content: counter(my-awesome-counter) '. ';
    font-weight: bold;
  }
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  p {
    font-size: 15px;
    font-weight: 500;
  }
  @media (max-width: 414px) {
    padding: 0;
    width: 200px;
    button {
      height: 40px;
      padding: 0;
    }
  }
`;
