import PropTypes from 'prop-types';
import React from 'react';
import * as S from './styled';

const Layout = ({ side, children, contactPage, withoutPadding }) => (
  <S.Wrapper
    side={side}
    contactPage={contactPage}
    withoutPadding={withoutPadding}
  >
    {children}
  </S.Wrapper>
);

Layout.defaultProps = {
  side: 'default',
  contactPage: false,
  withoutPadding: false,
};
Layout.propTypes = {
  side: PropTypes.string,
  children: PropTypes.node.isRequired,
  contactPage: PropTypes.bool,
  withoutPadding: PropTypes.bool,
};

export default Layout;
