import React from 'react';
import PropTypes from 'prop-types';
import PlansMobile from './PlansMobile';
import PlansWeb from './PlansWeb';

const Plans = ({ plansPadding, plans }) => (
  <>
    <PlansWeb plans={plans} plansPadding={plansPadding} />
    <PlansMobile plans={plans} />
  </>
);

Plans.defaultProps = {
  plansPadding: '',
  plans: [],
};

Plans.propTypes = {
  plansPadding: PropTypes.string,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      personalizedService: PropTypes.bool,
      title: PropTypes.string,
      titleColor: PropTypes.string,
      userQuantity: PropTypes.string,
      firstValue: PropTypes.string,
      firstBtnType: PropTypes.string,
      firstBtnText: PropTypes.string,
      firstBtnBackgroundColor: PropTypes.string,
      firstBtnBackgroundColorHover: PropTypes.string,
      firstBtnBorderColor: PropTypes.string,
      firstBtnBorderColorHover: PropTypes.string,
      firstBtnTextColor: PropTypes.string,
      firstBtnTextColorHover: PropTypes.string,
      firstButtonScrollToId: PropTypes.string,
      firstButtonRedirect: PropTypes.string,
      firstButtonHref: PropTypes.string,
      firstButtonOnClick: PropTypes.func,
      listOfAdvantages: {
        checkColor: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.string),
      },
      secondBtnType: PropTypes.string,
      secondBtnBackgroundColor: PropTypes.string,
      secondBtnBackgroundColorHover: PropTypes.string,
      secondBtnBorderColor: PropTypes.string,
      secondBtnBorderColorHover: PropTypes.string,
      secondBtnText: PropTypes.string,
      secondBtnTextColor: PropTypes.string,
      secondBtnTextColorHover: PropTypes.string,
      secondButtonScrollToId: PropTypes.string,
      secondButtonRedirect: PropTypes.string,
      secondButtonHref: PropTypes.string,
      secondButtonOnClick: PropTypes.func,
      secondValue: PropTypes.string,
    }),
  ),
};

export default Plans;
