import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import * as S from './styled';

const Card = ({
  isDash,
  onClick,
  imgSrc,
  imgAlt,
  text,
  selectedValue,
  setSelectedValue,
  variableId,
}) => {
  const [checkState, setCheckState] = useState(false);
  const clientRef = useRef(null);
  const dashRef = useRef(null);

  useEffect(() => {
    const checked = selectedValue === variableId;
    setCheckState(checked);
  }, [selectedValue, variableId]);

  const selectedRef = {
    true: dashRef,
    false: clientRef,
  };

  const click = () => {
    setSelectedValue(variableId);
    selectedRef[isDash].current.click();
    onClick();
  };

  const isDashReturn = {
    true: (
      <Box py={1} px={2} display="flex" alignItems="center">
        <S.Image src={imgSrc} alt={imgAlt} dash />
        <Box px={2}>
          <Typography variant="h6">{text}</Typography>
        </Box>
        <input type="radio" id="dash" name="dash" ref={dashRef} />
      </Box>
    ),
    false: (
      <Box py={1} px={2} display="flex" alignItems="center">
        <S.Image src={imgSrc} alt={imgAlt} />
        <Box px={2}>
          <Typography variant="h6">{text}</Typography>
        </Box>
        <input type="radio" id="client" name="client" ref={clientRef} />
      </Box>
    ),
  };

  return (
    <Box m={1}>
      <S.Card elevation={2} borderActive={checkState} onClick={click}>
        {isDashReturn[isDash]}
      </S.Card>
    </Box>
  );
};

Card.defaultProps = {
  isDash: false,
  onClick: () => null,
  imgSrc: '',
  imgAlt: '',
  text: 'Text',
  selectedValue: '',
  setSelectedValue: () => null,
  variableId: '',
};

Card.propTypes = {
  isDash: PropTypes.bool,
  onClick: PropTypes.func,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  text: PropTypes.string,
  selectedValue: PropTypes.string,
  setSelectedValue: PropTypes.func,
  variableId: PropTypes.string,
};

export default Card;
