import scrollTo from 'gatsby-plugin-smoothscroll';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button';
import Layout from '../Layout';
import Typography from '../Typography';
import * as S from './styled';

const CtaFooterSession = ({
  title,
  subtitles,
  textColor,
  spanColor,
  btnText,
  btnTextColor,
  btnTextHoverColor,
  btnBorderColor,
  btnBorderHoverColor,
  btnBackgroundColor,
  btnBackgroundHoverColor,
  btnOpacity,
  btnOpacityHover,
  contactPage,
  ...props
}) => {
  const handleScroll = () => scrollTo('#lead-form');

  return (
    <Layout>
      <S.Container>
        <S.TitleWrapper id="ctaFooter" spanColor={spanColor}>
          {!!title &&
            title.map(text => (
              <Typography
                type="h"
                level="4"
                color={textColor}
                fontWeight="600"
                key={text}
              >
                {text}
              </Typography>
            ))}
        </S.TitleWrapper>
        {!!subtitles &&
          subtitles.map(subtitle => (
            <Typography
              type="h"
              level="4"
              color={textColor}
              fontWeight="600"
              key={subtitle}
            >
              {subtitle}
            </Typography>
          ))}
        <S.ButtonWrapper>
          {contactPage ? (
            <Button
              text={btnText}
              textColor={btnTextColor}
              textHoverColor={btnTextHoverColor}
              borderColor={btnBorderColor}
              borderHoverColor={btnBorderHoverColor}
              backgroundColor={btnBackgroundColor}
              backgroundHoverColor={btnBackgroundHoverColor}
              opacity={btnOpacity}
              opacityHover={btnOpacityHover}
              onClick={handleScroll}
              fullWidth
            />
          ) : (
            <Button
              text={btnText}
              textColor={btnTextColor}
              textHoverColor={btnTextHoverColor}
              borderColor={btnBorderColor}
              borderHoverColor={btnBorderHoverColor}
              backgroundColor={btnBackgroundColor}
              backgroundHoverColor={btnBackgroundHoverColor}
              opacity={btnOpacity}
              opacityHover={btnOpacityHover}
              {...props}
            />
          )}
        </S.ButtonWrapper>
      </S.Container>
    </Layout>
  );
};

CtaFooterSession.defaultProps = {
  title: [],
  textColor: 'primary',
  spanColor: 'third',
  subtitles: [],
  btnText: 'Your text',
  btnTextColor: 'primary',
  btnTextHoverColor: 'secondary',
  btnBorderColor: 'primary',
  btnBorderHoverColor: 'third',
  btnBackgroundColor: 'primary',
  btnBackgroundHoverColor: 'third',
  btnOpacity: '0.9',
  btnOpacityHover: '0.7',
  contactPage: false,
};

CtaFooterSession.propTypes = {
  title: PropTypes.arrayOf(PropTypes.string),
  subtitles: PropTypes.arrayOf(PropTypes.string),
  textColor: PropTypes.string,
  spanColor: PropTypes.string,
  btnText: PropTypes.string,
  btnTextColor: PropTypes.string,
  btnTextHoverColor: PropTypes.string,
  btnBorderColor: PropTypes.string,
  btnBorderHoverColor: PropTypes.string,
  btnBackgroundColor: PropTypes.string,
  btnBackgroundHoverColor: PropTypes.string,
  btnOpacity: PropTypes.string,
  btnOpacityHover: PropTypes.string,
  contactPage: PropTypes.bool,
};

export default CtaFooterSession;
