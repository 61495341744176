import React, { useCallback } from 'react';
import { useSnackbar } from '../../providers/snackbar';
import * as S from './styled';

const Snackbar = () => {
  const { type, isOpen, setIsOpen, duration, message } = useSnackbar();

  const close = useCallback(() => {
    if (isOpen) setIsOpen(false);
  }, [isOpen, setIsOpen]);

  const hasMessage = message !== '';
  const defaultMessages = {
    success: 'This is a success message!',
    info: 'This is an information message!',
    warning: 'This is a warning message!',
    error: 'This is an error message!',
  };

  const setMessage = {
    true: message,
    false: defaultMessages[type],
  };

  return (
    <S.MaterialSnackBar
      open={isOpen && hasMessage}
      autoHideDuration={duration}
      onClose={close}
    >
      {hasMessage && (
        <S.CustomAlert onClose={close} severity={type}>
          <S.Text textColor={type}>{`${setMessage[hasMessage]}`}</S.Text>
        </S.CustomAlert>
      )}
    </S.MaterialSnackBar>
  );
};

export default Snackbar;
