import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const Divisor = ({
  color,
  height,
  width,
  margin,
  padding,
  borderRadius,
  ml,
}) => (
  <Wrapper
    color={color}
    height={height}
    width={width}
    margin={margin}
    padding={padding}
    borderRadius={borderRadius}
    ml={ml}
  />
);

export const Wrapper = styled.main`
  ${({ theme, height, width, color, borderRadius, margin, padding, ml }) => css`
    height: ${height};
    width: ${width};
    background-color: ${theme.colors[color]};
    border-radius: ${borderRadius};
    margin: ${margin};
    padding: ${padding};

    ${ml &&
    css`
      margin: 5px auto 30px 200px;
      ${media.lessThan('1921px')`
        margin-left: 150px;
      `};
      ${media.lessThan('1681px')`
        margin-left: 100px;
      `};
      ${media.lessThan('1025px')`
        margin-left: 40px;
      `};
    `}
  `}
`;

Divisor.defaultProps = {
  color: 'primary',
  height: '4px',
  width: '35px',
  margin: '0',
  padding: '0',
  borderRadius: '20%',
  ml: false,
};

Divisor.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  borderRadius: PropTypes.string,
  ml: PropTypes.bool,
};

export default Divisor;
