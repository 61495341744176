import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

function SEO({ title, url, description, image, lang, author }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: `ìmage`,
          property: `og:image`,
          content: image,
        },
        {
          name: `ìmage`,
          property: `og:image:secure_url`,
          content: image,
        },
        {
          name: `ìmage`,
          property: `og:image:type`,
          content: `image/png`,
        },
        {
          name: `ìmage`,
          property: `og:image:width`,
          content: `400`,
        },
        {
          name: `ìmage`,
          property: `og:image:height`,
          content: `400`,
        },
        {
          name: `ìmage`,
          property: `og:image:alt`,
          content: `site favIcon`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `author`,
          content: author,
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
    >
      <title>{title}</title>
    </Helmet>
  );
}

SEO.defaultProps = {
  title: 'We.Dash',
  description: 'Dashboards de marketing, vendas e growth em um só lugar.',
  url: 'https://www.bowe.com.br/',
  image:
    'https://i.pinimg.com/originals/4e/82/a5/4e82a56ddd726c0a171c2d2b0a69c4f2.png',
  lang: 'pt_BR',
  author: '@Bowe',
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  author: PropTypes.string,
};

export default SEO;
