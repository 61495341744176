import PropTypes from 'prop-types';
import React from 'react';
import Divisor from '../Divisor';
import Layout from '../Layout';
import Plans from '../Plans';
import Typography from '../Typography';
import * as S from './styled';

const PlansSession = ({
  title,
  subtitles,
  plans,
  plansPadding,
  divisorColor,
  divisorHeight,
  divisorWidth,
  divisorMargin,
}) => (
  <Layout withoutPadding>
    <S.Container id="planos">
      <Typography type="h" level="4" textAlign="center">
        {title}
      </Typography>
      <Divisor
        margin={divisorMargin}
        color={divisorColor}
        width={divisorWidth}
        height={divisorHeight}
      />
      {subtitles &&
        subtitles?.map(subtitle => (
          <Typography
            type="h"
            level="5"
            fontWeight="400"
            textAlign="center"
            margin="0 20px 30px"
          >
            {subtitle}
          </Typography>
        ))}
      <Plans
        plans={plans}
        plansPadding={plansPadding}
        id="planos"
        contactPage
      />
    </S.Container>
  </Layout>
);

PlansSession.defaultProps = {
  title: '',
  subtitles: [''],
  plansPadding: '',
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      personalizedService: PropTypes.bool,
      title: PropTypes.string,
      titleColor: PropTypes.string,
      userQuantity: PropTypes.string,
      firstValue: PropTypes.string,
      firstBtnType: PropTypes.string,
      firstBtnText: PropTypes.string,
      firstBtnBackgroundColor: PropTypes.string,
      firstBtnBackgroundColorHover: PropTypes.string,
      firstBtnBorderColor: PropTypes.string,
      firstBtnBorderColorHover: PropTypes.string,
      firstBtnTextColor: PropTypes.string,
      firstBtnTextColorHover: PropTypes.string,
      firstButtonScrollToId: PropTypes.string,
      firstButtonRedirect: PropTypes.string,
      firstButtonHref: PropTypes.string,
      firstButtonOnClick: PropTypes.func,
      listOfAdvantages: {
        checkColor: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.string),
      },
      secondBtnType: PropTypes.string,
      secondBtnBackgroundColor: PropTypes.string,
      secondBtnBackgroundColorHover: PropTypes.string,
      secondBtnBorderColor: PropTypes.string,
      secondBtnBorderColorHover: PropTypes.string,
      secondBtnText: PropTypes.string,
      secondBtnTextColor: PropTypes.string,
      secondBtnTextColorHover: PropTypes.string,
      secondButtonScrollToId: PropTypes.string,
      secondButtonRedirect: PropTypes.string,
      secondButtonHref: PropTypes.string,
      secondButtonOnClick: PropTypes.func,
      secondValue: PropTypes.string,
    }),
  ),
  divisorColor: 'primary',
  divisorHeight: '5px',
  divisorWidth: '55px',
  divisorMargin: '20px 0',
};

PlansSession.propTypes = {
  title: PropTypes.string,
  subtitles: PropTypes.node,
  plansPadding: PropTypes.string,
  plans: PropTypes.arrayOf(),
  divisorColor: PropTypes.string,
  divisorHeight: PropTypes.string,
  divisorWidth: PropTypes.string,
  divisorMargin: PropTypes.string,
};

export default PlansSession;
