import styled from 'styled-components';

export const Container = styled.section`
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  @media (max-width: 768px) {
    h5 {
      font-size: 25px;
    }
    p {
      font-size: 20px;
    }
  }

  content-visibility: auto;
`;
