import scrollTo from 'gatsby-plugin-smoothscroll';
import PropTypes from 'prop-types';
import React from 'react';
import MAKE_WEDASH from '../../assets/img/makeImg2x.webp';
import Button from '../Button';
import Layout from '../Layout';
import Typography from '../Typography';
import * as S from './styled';

const MakeSession = ({
  bgColor,
  textColor,
  title,
  subtitle,
  btnText,
  btnTextColor,
  btnTextHoverColor,
  btnBackgroundColor,
  btnBackgroundHoverColor,
  btnBorderColor,
  btnBorderHoverColor,
  mobileSubtitle,
  contactPage,
  ...props
}) => {
  const handleScroll = () => scrollTo('#lead-form');

  return (
    <S.Container id="make" bgColor={bgColor}>
      <Layout side="right">
        <S.Wrapper>
          <S.TextWrapper>
            <Typography type="h" level="2" fontWeight="600" color={textColor}>
              {title}
            </Typography>
            <Typography type="h" level="5" color={textColor} fontWeight="400">
              {subtitle?.reduce((acc, current) => `${acc}${current} `, '')}
            </Typography>

            <S.ButtonWrapper>
              {contactPage ? (
                <Button
                  text={btnText}
                  textColor={btnTextColor}
                  textHoverColor={btnTextHoverColor}
                  backgroundColor={btnBackgroundColor}
                  backgroundHoverColor={btnBackgroundHoverColor}
                  borderColor={btnBorderColor}
                  borderHoverColor={btnBorderHoverColor}
                  fullWidth
                  onClick={handleScroll}
                />
              ) : (
                <Button
                  text={btnText}
                  textColor={btnTextColor}
                  textHoverColor={btnTextHoverColor}
                  backgroundColor={btnBackgroundColor}
                  backgroundHoverColor={btnBackgroundHoverColor}
                  borderColor={btnBorderColor}
                  borderHoverColor={btnBorderHoverColor}
                  fullWidth
                  {...props}
                />
              )}
            </S.ButtonWrapper>
          </S.TextWrapper>
          <S.MobileTextWrapper>
            <Typography
              type="h"
              level="3"
              fontWeight="600"
              color={textColor}
              textAlign="left"
              margin="30px 0"
            >
              {title}
            </Typography>
            <Typography type="p" level="1" color={textColor} lineHeight="33px">
              {mobileSubtitle}
            </Typography>
            <S.ButtonWrapper>
              {contactPage ? (
                <Button
                  text={btnText}
                  textColor={btnTextColor}
                  textHoverColor={btnTextHoverColor}
                  backgroundColor={btnBackgroundColor}
                  backgroundHoverColor={btnBackgroundHoverColor}
                  borderColor={btnBorderColor}
                  borderHoverColor={btnBorderHoverColor}
                  fullWidth
                  onClick={handleScroll}
                />
              ) : (
                <Button
                  text={btnText}
                  textColor={btnTextColor}
                  textHoverColor={btnTextHoverColor}
                  backgroundColor={btnBackgroundColor}
                  backgroundHoverColor={btnBackgroundHoverColor}
                  borderColor={btnBorderColor}
                  borderHoverColor={btnBorderHoverColor}
                  fullWidth
                  {...props}
                />
              )}
            </S.ButtonWrapper>
          </S.MobileTextWrapper>
          <S.StyledImg>
            <img
              src={MAKE_WEDASH}
              alt="Dois quadros colocados em cascata, um atrás do outro, demonstrando vários quadros de estatísticas de negócios"
            />
          </S.StyledImg>
        </S.Wrapper>
      </Layout>
    </S.Container>
  );
};

MakeSession.defaultProps = {
  bgColor: 'primary',
  textColor: '',
  title: '',
  subtitle: [],
  mobileSubtitle: 'your subtitle',
  btnText: 'Your text',
  btnTextColor: '',
  btnTextHoverColor: '',
  btnBackgroundColor: '',
  btnBackgroundHoverColor: '',
  btnBorderColor: '',
  btnBorderHoverColor: '',
  contactPage: false,
};

MakeSession.propTypes = {
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.arrayOf(PropTypes.string),
  mobileSubtitle: PropTypes.string,
  btnText: PropTypes.string,
  btnTextColor: PropTypes.string,
  btnTextHoverColor: PropTypes.string,
  btnBackgroundColor: PropTypes.string,
  btnBackgroundHoverColor: PropTypes.string,
  btnBorderColor: PropTypes.string,
  btnBorderHoverColor: PropTypes.string,
  contactPage: PropTypes.bool,
};

export default MakeSession;
