import styled, { css } from 'styled-components';
import { defaultTheme } from '../../styles/theme';

export const Container = styled.div`
  padding: 100px 0;

  @media (max-width: 540px) {
    padding: 20px 0;
  }

  content-visibility: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 540px) {
    flex-direction: column;
  }
`;

export const Image = styled.div`
  ${({ showGraphic }) =>
    !showGraphic &&
    css`
      display: none;
    `}
  width: auto;
  @media (max-width: 1367px) {
    img {
      width: 90%;
    }
  }

  @media (max-width: 540px) {
    img {
      width: 100%;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  @media (max-width: 1440px) {
    width: 60%;
  }
  @media (max-width: 540px) {
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;

  h4:last-child {
    color: ${defaultTheme.typography.fourth};
  }
`;

export const TextWrapper = styled.div`
  padding-top: 20px;
  width: 40%;

  @media (max-width: 1920px) {
    width: 70%;
  }
  @media (max-width: 1440px) {
    width: 80%;
  }
  @media (max-width: 1367px) {
    width: 85%;
  }
  @media (max-width: 540px) {
    padding-top: 0px;
    width: 100%;
    p {
      margin: 20px 0;
    }
  }
`;
