import scrollTo from 'gatsby-plugin-smoothscroll';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Button from '../Button';
import Layout from '../Layout';
import Typography from '../Typography';
import * as S from './styled';

const WeAreSession = ({
  titles,
  children,
  graphics,
  changeImgTime,
  redirect,
  btnText,
  btnTextColor,
  btnTextColorHover,
  btnBackgroundColor,
  btnBackgroundColorHover,
  btnBorderColor,
  btnBorderColorHover,
  contactPage,
  ...props
}) => {
  const handleScroll = () => scrollTo('#lead-form');

  const [infoBox, setInfoBox] = useState(0);
  const ACCUMULATOR = 1;
  useEffect(() => {
    const changeContentBox = setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      infoBox === graphics.length - ACCUMULATOR
        ? setInfoBox(0)
        : setInfoBox(infoBox + ACCUMULATOR);
      return infoBox;
    }, changeImgTime);
    return () => clearTimeout(changeContentBox);
  }, [infoBox, changeImgTime, graphics]);

  return (
    <S.Container id="produto">
      <Layout>
        <S.Wrapper>
          <S.Text>
            <S.TitleWrapper>
              {!!titles &&
                titles.map(title => {
                  return (
                    <Typography type="h" level="4" key={title}>
                      {title}
                    </Typography>
                  );
                })}
            </S.TitleWrapper>
            <S.TextWrapper>
              <Typography type="p" level="1" margin="30px 0">
                {children}
              </Typography>
              <div>
                {contactPage ? (
                  <Button
                    text={btnText}
                    textColor={btnTextColor}
                    textHoverColor={btnTextColorHover}
                    backgroundColor={btnBackgroundColor}
                    backgroundHoverColor={btnBackgroundColorHover}
                    borderColor={btnBorderColor}
                    borderHoverColor={btnBorderColorHover}
                    onClick={handleScroll}
                    fullWidth
                    {...props}
                  />
                ) : (
                  <Button
                    text={btnText}
                    textColor={btnTextColor}
                    textHoverColor={btnTextColorHover}
                    backgroundColor={btnBackgroundColor}
                    backgroundHoverColor={btnBackgroundColorHover}
                    borderColor={btnBorderColor}
                    borderHoverColor={btnBorderColorHover}
                    redirect={redirect}
                    fullWidth
                    {...props}
                  />
                )}
              </div>
            </S.TextWrapper>
          </S.Text>
          {!!graphics &&
            graphics.map((graphic, index) => {
              return (
                <S.Image key={graphic} showGraphic={infoBox === index}>
                  <img
                    src={graphic}
                    alt="alt"
                    loading="lazy"
                    decoding="async"
                  />
                </S.Image>
              );
            })}
        </S.Wrapper>
      </Layout>
    </S.Container>
  );
};

WeAreSession.defaultProps = {
  titles: [],
  graphics: [],
  changeImgTime: 20000,
  redirect: '',
  btnText: 'Your text',
  btnTextColor: 'secondary',
  btnTextColorHover: 'secondary',
  btnBackgroundColor: 'primary',
  btnBackgroundColorHover: 'primary',
  btnBorderColor: 'primary',
  btnBorderColorHover: 'primary',
  contactPage: false,
};

WeAreSession.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.element.isRequired,
  graphics: PropTypes.arrayOf(PropTypes.string),
  redirect: PropTypes.string,
  changeImgTime: PropTypes.number,
  btnText: PropTypes.string,
  btnTextColor: PropTypes.string,
  btnTextColorHover: PropTypes.string,
  btnBackgroundColor: PropTypes.string,
  btnBackgroundColorHover: PropTypes.string,
  btnBorderColor: PropTypes.string,
  btnBorderColorHover: PropTypes.string,
  contactPage: PropTypes.bool,
};

export default WeAreSession;
