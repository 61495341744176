import { FormControlLabel as MUIFormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import { defaultTheme } from '../../styles/theme';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${defaultTheme.colors.transparent};
  margin: 5px;
  > p {
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
    color: ${defaultTheme.alert.error};
  }
`;

export const FormControlLabel = styled(MUIFormControlLabel)`
  .MuiTypography-body1 {
    font-size: 10px;
  }
`;
