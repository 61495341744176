import styled from 'styled-components';
import { defaultTheme } from '../../styles/theme';

export const Container = styled.div`
  padding-top: 5%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${defaultTheme.colors.transparent};
  z-index: 1;
  @media (max-width: 1920px) {
    padding: 5% 5% 0;
  }
  @media (max-width: 1366px) {
    padding: 2% 5% 0;
  }
  @media (max-width: 1280px) {
    padding: 2% 5% 0;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (max-width: 400px) {
    padding: 0;
  }

  p {
    font-family: 'Roboto', sans-serif;
  }
`;

export const TextBox = styled.div`
  margin: 30px 0;
  > p {
    font-size: 20px;
  }
  @media (max-width: 1440px) {
    > p {
      font-size: 15px;
    }
  }
  @media (max-width: 1024px) {
    margin: 10px 0;
    > p {
      text-align: center;
    }
  }
`;

export const TextWrapper = styled.div`
  > img {
    width: 200px;
    margin: 10px 0;
  }
  background-color: white;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
`;

export const FormBody = styled.form`
  width: 599px;
  padding: 50px 0px;
  border-radius: 10px;
  background: ${defaultTheme.colors.white};
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  padding: 30px 25px;

  @media (max-width: 1680px) {
    width: 499px;
  }
  @media (max-width: 540px) {
    margin-bottom: 40px;
    width: 100%;
  }
`;

export const TitleForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  p {
    color: ${defaultTheme.typography.seventh};
  }
  p:first-child {
    color: ${defaultTheme.typography.sixth};
  }
  @media (max-width: 1367px) {
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    p {
      font-size: 20px;
    }
  }
  @media (max-width: 540px) {
    p {
      text-align: center;
      font-size: 16px;
    }
  }
`;

export const FormLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    width: ${({ max }) => (max ? '100%' : '50%')};
  }
  @media (max-width: 414px) {
    width: 100%;
    > div {
      width: 100%;
    }
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100;
  margin-top: 20px;
  @media (max-width: 1366px) {
    margin-top: 5px;
  }
  p {
    font-size: 20px;
    font-weight: 500;
  }
  @media (max-width: 1680px) {
    p {
      font-size: 15px;
    }
  }
`;
