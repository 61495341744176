import styled, { css } from 'styled-components';

export const ButtonContainer = styled.div`
  width: 18.75rem;
  margin: 0 auto;
  @media (max-width: 414px) {
    width: 90%;
  }
`;

const defaultImgStyles = css`
  a {
    display: flex;
    img {
      width: 80%;
      max-width: 180px;
      margin: 0 auto;
      filter: grayscale(100) opacity(0.7) contrast(0.2);
      transition: all 0.8s ease;
    }
    :hover {
      img {
        filter: none;
        transform: scale(1.13);
        transition: all 0.8s ease;
      }
    }
  }
`;

export const Container = styled.section`
  padding: 100px 0;

  content-visibility: auto;
`;

export const ToolsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ToolContent = styled.div`
  width: 20%;
  padding-bottom: 40px;
  align-self: center;
  justify-self: center;
  ${defaultImgStyles};

  @media (max-width: 1000px) {
    width: 25%;
  }

  @media (max-width: 650px) {
    width: 33%;
  }

  @media (max-width: 400px) {
    width: 50%;
  }
`;
