import React from 'react';
import PropTypes from 'prop-types';
import { defaultTheme } from '../../styles/theme';
import * as S from './styled';

const Button = ({
  text,
  redirectDuration,
  redirectColor,
  redirect,
  onClick,
  disabled,
  size,
  fullWidth,
  ...props
}) => {
  const redirectDisabledControl = {
    false: {
      paintDrip: true,
      duration: redirectDuration,
      color: defaultTheme.colors[redirectColor],
      to: redirect,
    },
  };

  if (redirect) {
    return (
      <S.AniLink {...redirectDisabledControl[disabled]}>
        <S.Button
          size={size}
          fullWidth={fullWidth}
          {...props}
          disabled={disabled}
        >
          {text}
        </S.Button>
      </S.AniLink>
    );
  }

  return (
    <S.Button
      size={size}
      fullWidth={fullWidth}
      {...props}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </S.Button>
  );
};

Button.defaultProps = {
  text: 'Seu texto aqui',
  size: 'sm',
  onClick: () => null,
  redirect: '',
  redirectDuration: 1,
  redirectColor: 'third',
  disabled: false,
  fullWidth: false,
};

Button.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  redirect: PropTypes.string,
  redirectDuration: PropTypes.number,
  redirectColor: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default Button;
