import PropTypes from 'prop-types';
import React from 'react';
import HOME_INVESTMENT from '../../assets/img/homeInvestmentImg.svg';
import Button from '../Button';
import Layout from '../Layout';
import LeadFormSession from '../LeadFormSession';
import Typography from '../Typography';
import * as S from './styled';

const OptimizeSession = ({
  title,
  text,
  boldText,
  imgSrc,
  imgAlt,
  btnText,
  btnTextColor,
  btnTextColorHover,
  btnBackgroundColor,
  btnBackgroundColorHover,
  btnBorderColor,
  btnBorderColorHover,
  redirect,
  contactPage,
  ...props
}) => (
  <S.Container>
    {contactPage && (
      <S.LeftImage>
        <img
          src={HOME_INVESTMENT}
          alt="card com dados de investimento em anúncios"
          loading="eager"
          decoding="async"
        />
      </S.LeftImage>
    )}
    <Layout withoutPadding>
      <S.Wrapper>
        <S.Body>
          <S.TitleWrapper>
            <Typography type="h" level="4">
              {title}
            </Typography>
          </S.TitleWrapper>
          <S.TextWrapper>
            <Typography type="p" level="1" margin="30px 0">
              {text}
            </Typography>
            {!contactPage && (
              <S.ButtonWrapper>
                <Button
                  text={btnText}
                  textColor={btnTextColor}
                  textHoverColor={btnTextColorHover}
                  backgroundColor={btnBackgroundColor}
                  backgroundHoverColor={btnBackgroundColorHover}
                  borderColor={btnBorderColor}
                  borderHoverColor={btnBorderColorHover}
                  redirect={redirect}
                  fullWidth
                  {...props}
                />
              </S.ButtonWrapper>
            )}
          </S.TextWrapper>
        </S.Body>
        {!contactPage ? (
          <S.Image>
            <img src={imgSrc} alt={imgAlt} loading="eager" decoding="async" />
          </S.Image>
        ) : (
          <section id="lead-form">
            <LeadFormSession
              contactPage
              titleForm={['Preencha seus dados', 'e fale com um especialista:']}
            />
          </section>
        )}
      </S.Wrapper>
    </Layout>
  </S.Container>
);

OptimizeSession.defaultProps = {
  title: 'Your Title',
  text: 'Your Text',
  boldText: '',
  imgSrc: null,
  imgAlt: 'alt img',
  btnText: 'Your text',
  btnTextColor: 'secondary',
  btnTextColorHover: 'secondary',
  btnBackgroundColor: 'primary',
  btnBackgroundColorHover: 'primary',
  btnBorderColor: 'primary',
  btnBorderColorHover: 'primary',
  redirect: '',
  contactPage: false,
};

OptimizeSession.propTypes = {
  title: PropTypes.string,
  boldText: PropTypes.string,
  redirect: PropTypes.string,
  text: PropTypes.string,
  imgSrc: PropTypes.node,
  imgAlt: PropTypes.string,
  btnText: PropTypes.string,
  btnTextColor: PropTypes.string,
  btnTextColorHover: PropTypes.string,
  btnBackgroundColor: PropTypes.string,
  btnBackgroundColorHover: PropTypes.string,
  btnBorderColor: PropTypes.string,
  btnBorderColorHover: PropTypes.string,
  contactPage: PropTypes.bool,
};

export default OptimizeSession;
