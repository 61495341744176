import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import size from 'lodash/size';
import trim from 'lodash/trim';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import * as S from './styled';

const Input = ({ type, name, label, options, errors, control }) => {
  const [phone, setPhone] = useState('(99) 99999-9999');
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const inputMaskRef = useRef(null);

  const handlePhoneMask = mask => {
    const {
      current: { value: eventValue },
    } = inputMaskRef;
    if (size(trim(eventValue, '_')) === 14) {
      setPhone(mask);
    }
  };

  const changePasswordVisibility = () => {
    setPasswordVisibility(prevState => !prevState);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  function testLetterRegex(value) {
    const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ ]*$/.test(value);
    return regex;
  }

  const RenderInput = {
    text: (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            id="outlined-required"
            variant="outlined"
            label={label}
            fullWidth
            {...field}
          />
        )}
      />
    ),
    phone: (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <InputMask
            mask={phone}
            {...field}
            ref={inputMaskRef}
            onBlur={() => handlePhoneMask('(99) 9999-9999')}
          >
            {inputProps => (
              <TextField
                id="outlined-required"
                variant="outlined"
                label={label}
                type="tel"
                {...inputProps}
              />
            )}
          </InputMask>
        )}
      />
    ),
    select: (
      <FormControl variant="outlined">
        <InputLabel htmlFor={`outlined-${label}-native-simple`}>
          {label}
        </InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label={label}
              inputProps={{
                name,
                id: `outlined-${label}-native-simple`,
              }}
            >
              {options?.map(option => (
                <MenuItem value={option.value}>{option.name}</MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    ),
    password: (
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <OutlinedInput
              {...field}
              id="outlined-adornment-password"
              type={passwordVisibility ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={changePasswordVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          )}
        />
      </FormControl>
    ),
    name: (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ...rest } }) => (
          <TextField
            {...rest}
            value={value}
            onChange={event => {
              if (testLetterRegex(event.target.value)) {
                onChange(event);
              }
            }}
            id="outlined-required"
            variant="outlined"
            label={label}
            fullWidth
          />
        )}
      />
    ),
    checkbox: (
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <S.FormControlLabel
            control={<Checkbox {...field} color="primary" />}
            label={label}
          />
        )}
      />
    ),
  };
  return (
    <S.InputWrapper>
      {RenderInput[type]}
      <p>{errors}</p>
    </S.InputWrapper>
  );
};

Input.defaultProps = {
  type: 'text',
  name: 'name',
  label: 'label',
  options: [],
  errors: null,
  control: null,
};

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'phone', 'password', 'select', 'checkbox']),
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  errors: PropTypes.node,
  control: PropTypes.node,
};

export default Input;
