import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Button from '../Button';
import Layout from '../Layout';
import Typography from '../Typography';
import * as S from './styled';

const DataNoticesSession = ({ data, changeTime }) => {
  const [infoBox, setInfoBox] = useState(0);
  const ACCUMULATOR = 1;
  useEffect(() => {
    const changeContentBox = setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      infoBox === data.length - ACCUMULATOR
        ? setInfoBox(0)
        : setInfoBox(infoBox + ACCUMULATOR);
      return infoBox;
    }, changeTime);
    return () => clearTimeout(changeContentBox);
  }, [infoBox, changeTime, data]);

  return (
    <Layout>
      <S.Container id="comunidade">
        <S.NoticesCard>
          <S.Wrapper>
            <S.Nav>
              {!!data &&
                data.map((nav, index) => {
                  return (
                    <S.NavLinks
                      key={nav}
                      activeLink={infoBox === index}
                      onClick={() => setInfoBox(index)}
                    >
                      <Typography type="p" level="1" color={nav.navColor}>
                        {nav.navTitle}
                      </Typography>
                    </S.NavLinks>
                  );
                })}
            </S.Nav>
            {!!data &&
              data.map((content, index) => {
                return (
                  <S.Body activeBody={infoBox === index}>
                    <Typography
                      type="p"
                      level="1"
                      fontWeight="400"
                      color={content.textColor}
                      opacity={content.textOpacity}
                    >
                      {`${content.text} `}
                      <strong>{content.strongText}</strong>
                    </Typography>
                    <S.ButtonWrapper>
                      <Button {...content.button} size="md" />
                    </S.ButtonWrapper>
                  </S.Body>
                );
              })}
          </S.Wrapper>
          <S.Img />
        </S.NoticesCard>
      </S.Container>
    </Layout>
  );
};

DataNoticesSession.defaultProps = {
  changeTime: 2000,
  data: [],
};

DataNoticesSession.propTypes = {
  changeTime: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      navTitle: PropTypes.string,
      navColor: PropTypes.string,
      text: PropTypes.string,
      strongText: PropTypes.string,
      textColor: PropTypes.string,
      textOpacity: PropTypes.string,
      button: PropTypes.shape({
        text: PropTypes.string,
        margin: PropTypes.string,
        size: PropTypes.string,
        textColor: PropTypes.string,
        textHoverColor: PropTypes.string,
        borderColor: PropTypes.string,
        borderHoverColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        backgroundHoverColor: PropTypes.string,
        opacity: PropTypes.string,
        opacityHover: PropTypes.string,
        borderRadius: PropTypes.string,
        shadow: PropTypes.bool,
        shadowColor: PropTypes.string,
        onClick: PropTypes.func,
        scrollToId: PropTypes.string,
        href: PropTypes.string,
        redirect: PropTypes.string,
        redirectDuration: PropTypes.number,
        redirectColor: PropTypes.string,
        disabled: PropTypes.bool,
        fullWidth: PropTypes.bool,
        largeText: PropTypes.bool,
      }),
    }),
  ),
};

export default DataNoticesSession;
