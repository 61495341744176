import { Link } from '@material-ui/core';
import styled from 'styled-components';
import { defaultTheme } from '../../styles/theme';

export const Footer = styled.footer`
  background-color: ${({ backgroundColor }) =>
    defaultTheme.colors[backgroundColor] || defaultTheme.colors.white};
  padding: 52px 0;

  content-visibility: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 540px) {
    flex-direction: column-reverse;
    > * + * {
      margin-bottom: 30px;
    }
  }
`;

export const Logo = styled.img`
  width: 210px;
  cursor: pointer;

  :hover {
    opacity: 0.7s;
    transition: all 0.8 ease;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SocialWrapper = styled.div`
  margin: 0 27px;
  img {
    width: 30px;
    filter: brightness(0) contrast(100) invert(100%);
    transition: all 0.8s ease;
  }

  :hover {
    img {
      filter: none;
      transform: scale(1.13);
      transition: all 0.8s ease;
    }
  }

  @media (max-width: 540px) {
    margin: 0 10px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;

  @media (max-width: 45em) {
    flex-direction: column;
  }
`;

export const PoliciesLink = styled(Link)`
  color: ${defaultTheme.colors.white};
  font-family: 'Montserrat', sans-serif;

  margin-left: 1rem;
`;
