import scrollTo from 'gatsby-plugin-smoothscroll';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button';
import Divisor from '../Divisor';
import Layout from '../Layout';
import Typography from '../Typography';
import * as S from './styled';

const IntegrationSession = ({
  title,
  subtitle,
  tools,
  divisorColor,
  divisorHeight,
  divisorWidth,
  divisorMargin,
  btnText,
  btnTextColor,
  btnTextColorHover,
  btnBackgroundColor,
  btnBackgroundColorHover,
  btnBorderColor,
  btnBorderColorHover,
  redirect,
  contactPage,
  ...props
}) => {
  const handleScroll = () => scrollTo('#lead-form');

  return (
    <Layout>
      <S.Container id="integration">
        <Typography type="h" level="4">
          {title}
        </Typography>
        <Divisor
          margin={divisorMargin}
          color={divisorColor}
          width={divisorWidth}
          height={divisorHeight}
        />
        <Typography type="p" level="1" margin="40px 0" opacity="0.9">
          {subtitle}
        </Typography>
        <S.ToolsWrapper>
          {!!tools &&
            tools.map(tool => {
              return (
                <S.ToolContent key={tool}>
                  <a href={tool.redirect} target="_blank" rel="noreferrer">
                    <img
                      src={tool.imgSrc}
                      alt={tool.imgAlt}
                      loading="lazy"
                      decoding="async"
                    />
                  </a>
                </S.ToolContent>
              );
            })}
        </S.ToolsWrapper>
        <S.ButtonContainer>
          {contactPage ? (
            <Button
              text={btnText}
              textColor={btnTextColor}
              textHoverColor={btnTextColorHover}
              backgroundColor={btnBackgroundColor}
              backgroundHoverColor={btnBackgroundColorHover}
              borderColor={btnBorderColor}
              borderHoverColor={btnBorderColorHover}
              onClick={handleScroll}
              fullWidth
              {...props}
            />
          ) : (
            <Button
              text={btnText}
              textColor={btnTextColor}
              textHoverColor={btnTextColorHover}
              backgroundColor={btnBackgroundColor}
              backgroundHoverColor={btnBackgroundColorHover}
              borderColor={btnBorderColor}
              borderHoverColor={btnBorderColorHover}
              redirect={redirect}
              fullWidth
              {...props}
            />
          )}
        </S.ButtonContainer>
      </S.Container>
    </Layout>
  );
};

IntegrationSession.defaultProps = {
  title: 'Your title',
  subtitle: 'Your subtitle',
  tools: [],
  divisorColor: 'primary',
  divisorHeight: '4px',
  divisorWidth: '35px',
  divisorMargin: '20px 0',
  btnText: 'Your text',
  btnTextColor: 'secondary',
  btnTextColorHover: 'secondary',
  btnBackgroundColor: 'primary',
  btnBackgroundColorHover: 'primary',
  btnBorderColor: 'primary',
  btnBorderColorHover: 'primary',
  redirect: '',
  contactPage: false,
};

IntegrationSession.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  redirect: PropTypes.string,
  tools: PropTypes.node,
  divisorColor: PropTypes.string,
  divisorHeight: PropTypes.string,
  divisorWidth: PropTypes.string,
  divisorMargin: PropTypes.string,
  btnText: PropTypes.string,
  btnTextColor: PropTypes.string,
  btnTextColorHover: PropTypes.string,
  btnBackgroundColor: PropTypes.string,
  btnBackgroundColorHover: PropTypes.string,
  btnBorderColor: PropTypes.string,
  btnBorderColorHover: PropTypes.string,
  contactPage: PropTypes.bool,
};

export default IntegrationSession;
