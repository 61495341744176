import styled, { css } from 'styled-components';
import WOMAN from '../../assets/img/womanPaper.webp';
import WOMAN2x from '../../assets/img/womanPaper2x.webp';
import { defaultTheme } from '../../styles/theme';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 100px 0;

  @media (max-width: 380px) {
    padding: 40px 0;
  }
`;

export const NoticesCard = styled.div`
  position: relative;
  border-radius: 50px;
  background-color: ${defaultTheme.colors.seventh};
  width: 1124px;
  min-height: 525px;
  max-height: 525px;

  :after {
    content: '';
    background-image: url(${WOMAN2x});
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 650px;
    height: 650px;
    right: -7%;
    bottom: -22px;
  }

  @media (max-width: 768px) {
    :after {
      display: none;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
`;

export const Img = styled.img.attrs({
  src: WOMAN,
  alt: 'Woman',
})`
  width: 80%;
  margin-bottom: -2%;
  margin-left: 10%;

  @media (min-width: 769px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 59%;
  max-width: 59%;
  padding: 76px 104px;

  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
    min-height: 500px;
  }
  @media (max-width: 540px) {
    padding: 76px 20px 0 20px;
    min-height: 300px;
  }
  @media (max-width: 330px) {
    padding: 26px 10px 0 10px;
  }
  @media (max-width: 330px) {
    min-height: 350px;
  }
`;

export const Nav = styled.div`
  display: flex;

  > span + span {
    margin-left: 25px;
  }
  @media (max-width: 540px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 330px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > span {
      margin: 10px 0;
    }
    > span + span {
      margin-left: 0;
    }
  }
`;

export const NavLinks = styled.span`
  > p {
    ${({ activeLink }) =>
      activeLink
        ? css`
            text-decoration: underline;
            opacity: 1;
          `
        : css`
            opacity: 0.6;
            text-decoration: none;
          `}
    font-weight: 500;
    transition: all 0.7s ease;
  }
  :hover {
    > p {
      cursor: pointer;
      opacity: 1;
      transition: all 0.7s ease;
    }
  }

  @media (max-width: 540px) {
    > p {
      font-size: 18px;
    }
  }
`;

export const Body = styled.div`
  ${({ activeBody }) =>
    !activeBody &&
    css`
      display: none;
    `}
  padding-top: 66px;
  width: 100%;
  text-align: left;

  > p:first-child {
    font-size: 25px;
    font-weight: 400;
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    > p:first-child {
      font-size: 18px;
    }
  }
  @media (max-width: 540px) {
    padding-top: 50px;
    > p:first-child {
      text-align: center;
      font-size: 14px;
    }
  }
  @media (max-width: 330px) {
    padding-top: 36px;
  }
`;

export const ButtonWrapper = styled.span`
  padding-top: 60px;
  display: flex;
  justify-content: flex-start;
  p {
    font-size: 18px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (max-width: 540px) {
    padding-top: 30px;
    button {
      height: 50px;
      padding: 0;
      p {
        font-size: 16px;
      }
    }
  }
`;
