import * as yup from 'yup';
import { VALIDATION_CONSTANTS } from '../utils/enum/validationConstantes';

const invalidDomains = [
  'gmail.',
  'yahoo.',
  'hotmail.',
  'live.',
  'aol.',
  'outlook.',
  'bol.',
  'uol.',
  'icloud.',
  'ig.',
];

const LeadFormSchema = yup.object().shape({
  businessModel: yup.string().required(VALIDATION_CONSTANTS.required),
  company: yup.string().required(VALIDATION_CONSTANTS.required),
  email: yup
    .string()
    .email()
    .required(VALIDATION_CONSTANTS.required)
    .test({
      message: 'O e-mail precisa ser corporativo',
      test: value => {
        const validation = invalidDomains.some(invalidDomain =>
          value?.toLowerCase()?.includes(invalidDomain.toLowerCase()),
        );
        return !validation;
      },
    }),
  fullName: yup.string().required(VALIDATION_CONSTANTS.required),
  website: yup.string().required(VALIDATION_CONSTANTS.required),
  iAgree: yup
    .bool()
    .oneOf([true], VALIDATION_CONSTANTS.termsNotAccepted)
    .required(VALIDATION_CONSTANTS.required),
  jobRole: yup.string().required(VALIDATION_CONSTANTS.required),
  phone: yup
    .string()
    .transform(value => value?.replace(/[^\d]/g, ''))
    .test(
      'phone',
      'O telefone deve conter no mínimo 10 números.',
      value => !value || value.length >= 10,
    )
    .required(VALIDATION_CONSTANTS.required),
  utm_term: yup.string(),
  utm_source: yup.string(),
  utm_medium: yup.string(),
  utm_campaign: yup.string(),
  utm_content: yup.string(),
});

export default LeadFormSchema;
