import React from 'react';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from '../../providers/snackbar';
import { defaultTheme } from '../../styles/theme';
import CustomSeo from '../CustomSeo';
import SnackBar from '../Snackbar';

function PageWrapper(Component, title, url, description, author, image, lang) {
  return props => (
    <>
      <CustomSeo
        title={title}
        url={url}
        description={description}
        image={image}
        lang={lang}
        author={author}
      />
      <ThemeProvider theme={defaultTheme}>
        <SnackbarProvider>
          <Component {...props} />
          <SnackBar />
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default PageWrapper;
