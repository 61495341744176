import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import Divisor from '../Divisor';
import Typography from '../Typography';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  div {
    width: 300px;
  }
`;

export const Container = styled.div`
  content-visibility: auto;
  ${({ haveVideo }) =>
    haveVideo
      ? css`
          padding: 100px 0;
        `
      : css`
          padding: 40px 0;
        `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Typography).attrs({
  type: 'h',
  level: '4',
})`
  margin-left: 200px;
  ${media.lessThan('1921px')`
    margin-left: 150px;
  `};
  ${media.lessThan('1681px')`
    margin-left: 100px;
  `};
  ${media.lessThan('1025px')`
    margin-left: 40px;
  `};
`;

export const Divider = styled(Divisor).attrs({
  width: '35px',
  height: '3px',
  ml: true,
})``;

export const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    display: none;
  }

  margin: 0 200px;
  ${media.lessThan('1921px')`
    margin: 0 150px;
  `};
  ${media.lessThan('1681px')`
    margin: 0 100px;
  `};
  ${media.lessThan('1025px')`
    margin: 0 40px;
  `};
`;
export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const IconsLine = styled.div`
  display: flex;
  align-items: initial;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-bottom: 10px;

  ${({ mobile }) =>
    mobile &&
    css`
      border-radius: 20px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 6%),
        0px 2px 2px 0px rgb(0 0 0 / 6%), 3px 3px 6px 0px rgb(0 0 0 / 6%);
      border: 1px solid rgba(0, 0, 0, 0.05);
    `}
`;

export const Image = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: 120px;
  padding-bottom: 20px;

  @media (max-width: 330px) {
    min-height: 100px;
    img {
      width: 60px;
    }
  }
`;

export const TextWrapper = styled.div`
  p:first-child {
    font-size: 20px;
    padding-bottom: 20px;
  }
  @media (max-width: 330px) {
    p:first-child {
      font-size: 18px;
      padding-bottom: 20px;
    }
  }
`;
