import scrollTo from 'gatsby-plugin-smoothscroll';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../Layout';
import * as S from './styled';

const Footer = ({ backgroundColor, logoSrc, logoAlt, logoScroll, social }) => {
  const doScrollEffect = () => scrollTo(`#${logoScroll}`);

  return (
    <S.Footer backgroundColor={backgroundColor} id="footer">
      <Layout>
        <S.Wrapper>
          <S.LeftContainer>
            <S.Logo src={logoSrc} alt={logoAlt} onClick={doScrollEffect} />
            <div>
              <S.PoliciesLink target="_blank" href="/policies">
                Política de privacidade
              </S.PoliciesLink>

              <S.PoliciesLink target="_blank" href="/terms">
                Termos de uso
              </S.PoliciesLink>
            </div>
          </S.LeftContainer>
          <S.SocialContainer>
            {!!social &&
              social.map(item => {
                return (
                  <S.SocialWrapper>
                    <a
                      key={item}
                      href={item.redirect}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={item.imgSrc}
                        alt={item.imgAlt}
                        loading="lazy"
                        decoding="async"
                      />
                    </a>
                  </S.SocialWrapper>
                );
              })}
          </S.SocialContainer>
        </S.Wrapper>
      </Layout>
    </S.Footer>
  );
};

Footer.defaultProps = {
  backgroundColor: 'third',
  logoSrc: '',
  logoAlt: null,
  logoScroll: '',
  social: [],
};
Footer.propTypes = {
  backgroundColor: PropTypes.string,
  logoSrc: PropTypes.node,
  logoAlt: PropTypes.string,
  logoScroll: PropTypes.string,
  social: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.node,
      imgAlt: PropTypes.string,
      redirect: PropTypes.string,
    }),
  ),
};

export default Footer;
