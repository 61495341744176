import scrollTo from 'gatsby-plugin-smoothscroll';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button';
import Divisor from '../Divisor';
import Layout from '../Layout';
import Typography from '../Typography';
import * as S from './styled';

const AboutSession = ({
  imgSrc,
  imgAlt,
  title,
  children,
  btnText,
  btnTextColor,
  btnTextHoverColor,
  btnBackgroundColor,
  btnBackgroundHoverColor,
  btnBorderColor,
  btnBorderHoverColor,
  contactPage,
  ...props
}) => {
  const handleScroll = () => scrollTo('#lead-form');

  return (
    <S.Container id="sobre">
      <Layout>
        <S.Wrapper>
          <S.Image>
            <img src={imgSrc} alt={imgAlt} loading="eager" decoding="async" />
          </S.Image>
          <S.Body>
            <Typography type="h" level="4">
              {title}
            </Typography>
            <Divisor margin="5px 0 30px 0" height="3px" />
            <Typography type="p" level="1">
              <S.List>{children}</S.List>
            </Typography>
            <S.ButtonWrapper>
              {contactPage ? (
                <Button
                  text={btnText}
                  textColor={btnTextColor}
                  textHoverColor={btnTextHoverColor}
                  backgroundColor={btnBackgroundColor}
                  backgroundHoverColor={btnBackgroundHoverColor}
                  borderColor={btnBorderColor}
                  borderHoverColor={btnBorderHoverColor}
                  margin="50px 0 0 0"
                  fullWidth
                  onClick={handleScroll}
                />
              ) : (
                <Button
                  text={btnText}
                  textColor={btnTextColor}
                  textHoverColor={btnTextHoverColor}
                  backgroundColor={btnBackgroundColor}
                  backgroundHoverColor={btnBackgroundHoverColor}
                  borderColor={btnBorderColor}
                  borderHoverColor={btnBorderHoverColor}
                  margin="50px 0 0 0"
                  fullWidth
                  {...props}
                />
              )}
            </S.ButtonWrapper>
          </S.Body>
        </S.Wrapper>
      </Layout>
    </S.Container>
  );
};

AboutSession.defaultProps = {
  imgSrc: null,
  title: 'Your title',
  btnText: 'Your text',
  btnTextColor: 'secondary',
  btnTextHoverColor: 'secondary',
  btnBackgroundColor: 'primary',
  btnBackgroundHoverColor: 'primary',
  btnBorderColor: 'primary',
  btnBorderHoverColor: 'primary',
  contactPage: false,
};

AboutSession.propTypes = {
  imgSrc: PropTypes.node,
  imgAlt: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
  btnText: PropTypes.string,
  btnTextColor: PropTypes.string,
  btnTextHoverColor: PropTypes.string,
  btnBackgroundColor: PropTypes.string,
  btnBackgroundHoverColor: PropTypes.string,
  btnBorderColor: PropTypes.string,
  btnBorderHoverColor: PropTypes.string,
  contactPage: PropTypes.bool,
};

export default AboutSession;
