import styled from 'styled-components';
import { defaultTheme } from '../../styles/theme';

export const Container = styled.section`
  min-height: 100%;
  background-color: ${({ bgColor }) => defaultTheme.colors[bgColor]};
  content-visibility: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15%;
  width: 50%;

  > h3 {
    font-size: 60px;
  }

  @media (max-width: 1920px) {
    padding-left: 10%;
  }

  @media (max-width: 1440px) {
    h3 {
      font-size: 50px;
    }
  }

  @media (max-width: 1367px) {
    h3 {
      font-size: 40px;
    }
    padding-left: 5%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileTextWrapper = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;

export const StyledImg = styled.div`
  min-width: 50%;
  max-width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  img {
    width: 100%;
  }

  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 60px;
  width: 410px;

  @media (max-width: 1441px) {
    width: 370px;
  }

  @media (max-width: 1367px) {
    width: 310px;
  }

  @media (max-width: 1280px) {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 260px;
  }

  @media (max-width: 375px) {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 200px;

    button {
      height: 50px;
    }
  }
`;
