import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

const Video = ({ url, autoPlay, loop, controls, lightMode }) => {
  const ruleOfAutoPlay = {
    true: {
      volume: 0,
    },
    false: {
      volume: 50,
    },
  };
  const videoMap = {
    home: 'https://www.youtube.com/watch?v=pXRviuL6vMY',
  };
  return (
    <S.Video
      url={videoMap[url]}
      playing={autoPlay}
      loop={loop}
      controls={controls}
      light={lightMode}
      muted={false}
      {...ruleOfAutoPlay[autoPlay]}
    />
  );
};

Video.defaultProps = {
  url: 'home',
  autoPlay: false,
  loop: false,
  controls: true,
  lightMode: true,
};

Video.propTypes = {
  url: PropTypes.string,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  controls: PropTypes.bool,
  lightMode: PropTypes.bool,
};

export default Video;
