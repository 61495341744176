import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  padding: 30px 0;
`;

export const LeftImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 90%;

    @media (max-width: 2000px) {
      width: 70%;
    }

    @media (max-width: 1300px) {
      width: 60%;
    }

    @media (max-width: 1188px) {
      width: 50%;
    }
  }

  @media (max-width: 1026px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  img {
    width: 90%;
  }
  @media (max-width: 1366px) {
    img {
      width: 90%;
    }
  }
  @media (max-width: 1024px) {
    img {
      padding-top: 40px;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 150px;
  padding-left: 200px;

  ${media.lessThan('1921px')`
    padding-left: 150px;
  `};
  ${media.lessThan('1681px')`
    padding-left: 100px;
  `};
  ${media.lessThan('1025px')`
    padding-left: 40px;
  `};
  ${media.lessThan('769px')`
    padding-top: 0;
    padding-right: 40px;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const TextWrapper = styled.div`
  padding-top: 10px;
  width: 50%;

  @media (max-width: 1920px) {
    width: 70%;
  }
  @media (max-width: 1440px) {
    width: 80%;
  }

  @media (max-width: 1280) {
    width: 100%;
  }
  @media (max-width: 1024) {
    width: 100%;
  }
  @media (max-width: 414px) {
    padding: 0;
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  background-color: white;
  width: 300px;
  padding-top: 20px;
  p {
    font-weight: 500;
  }
  @media (max-width: 414px) {
    background-color: none;
    padding: 0;
    width: 200px;
    button {
      height: 40px;
      padding: 0;
    }
  }
`;
