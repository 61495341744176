/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export const SnackBarContext = createContext({});

const SnackbarProvider = ({ children }) => {
  const [type, setType] = useState('success');
  const [isOpen, setIsOpen] = useState(false);
  const [duration, setDuration] = useState(3000);
  const [message, setMessage] = useState('');

  const showSnackbar = useCallback(
    ({ type = 'success', message, duration = 3000 }) => {
      setType(type);
      setMessage(message);
      setDuration(duration);
      setIsOpen(true);
    },
    [],
  );

  const resetStates = useCallback(() => {
    setType('success');
    setDuration(3000);
    setMessage('');
  }, []);

  useEffect(() => {
    if (!isOpen) {
      resetStates();
    }
  }, [isOpen, resetStates]);

  return (
    <SnackBarContext.Provider
      value={{
        type,
        setType,
        isOpen,
        setIsOpen,
        duration,
        setDuration,
        message,
        setMessage,
        showSnackbar,
      }}
    >
      {children}
    </SnackBarContext.Provider>
  );
};

function useSnackbar() {
  const context = useContext(SnackBarContext);
  if (!context) {
    throw new Error('useSnackBar must be used within a SnackBarProvider');
  }
  return context;
}

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SnackbarProvider, useSnackbar };
