import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import StyledAniLink from 'gatsby-plugin-transition-link/AniLink';
import { defaultTheme } from '../../styles/theme';

const sizeButtonMap = {
  sm: css`
    width: 18.75rem;
    ${media.lessThan('1681px')`
      width: 15.625rem;
    `};
    ${media.lessThan('1440px')`
      width: 12.5rem;
    `};
  `,
  md: css`
    width: 15.625rem;
  `,
  lg: css`
    width: 37.5rem;
  `,
  fullWidth: css`
    width: 100%;
  `,
};

export const AniLink = styled(StyledAniLink)`
  text-decoration: none;
`;

export const Button = styled.button`
  ${({
    fullWidth,
    color,
    size,
    margin,
    borderRadius,
    backgroundHoverColor,
    borderColor,
    textColor,
    largeText,
    opacityHover,
    textHoverColor,
    opacity,
    backgroundColor,
    borderHoverColor,
    shadow,
    fontSize,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${fullWidth ? '100%' : sizeButtonMap[size]};
    padding: 0.9375rem;
    cursor: pointer;
    outline: none;
    height: 2.5rem;
    font-weight: 900;
    text-transform: uppercase;
    transition: all 0.8s ease-out;
    color: ${defaultTheme.colors[color]};
    margin: ${margin};
    border-radius: ${borderRadius || '2.5rem'};
    opacity: ${opacity};
    background-color: ${defaultTheme.colors[backgroundColor]};
    border-color: ${defaultTheme.colors[borderColor]};
    box-shadow: ${shadow || 'none'};
    font-size: ${fontSize || largeText ? '100%' : '0.938rem'};
    color: ${defaultTheme.typography[textColor]};
    transition: color 0.8s ease-out;
    :hover {
      transform: scale(1.05);
      opacity: ${opacityHover};
      background-color: ${defaultTheme.colors[backgroundHoverColor]};
      border-color: ${defaultTheme.colors[borderHoverColor]};
      color: ${defaultTheme.typography[textHoverColor]};
      transition: all 0.8s ease-out;
    }
    :disabled {
      cursor: alias;
      opacity: ${opacity};
      background-color: ${defaultTheme.colors[backgroundColor]};
      border-color: ${defaultTheme.colors[borderColor]};
      color: ${defaultTheme.typography[textColor]};
    }
  `}
`;
