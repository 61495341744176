import MaterialUiSnackBar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import { defaultTheme } from '../../styles/theme';

export const MaterialSnackBar = styled(MaterialUiSnackBar)`
  min-width: 300px;
`;

export const CustomAlert = styled(Alert)`
  width: 100%;
`;

export const Text = styled.p`
  color: ${({ textColor }) => defaultTheme.alert[textColor]};
`;
