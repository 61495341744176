import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../Button';
import Carousel from '../../Carousel';
import Typography from '../../Typography';
import * as S from '../styled';

const PlansMobile = ({ plans }) => (
  <Carousel plans>
    {!!plans &&
      plans.map(plan => {
        const firstButtonMap = {
          scroolToId: {
            scrollToId: plan.firstButtonScrollToId,
            text: plan.firstBtnText,
            textColor: plan.firstBtnTextColor,
            textHoverColor: plan.firstBtnTextColorHover,
            borderColor: plan.firstBtnBorderColor,
            borderHoverColor: plan.firstBtnBorderColorHover,
            backgroundColor: plan.firstBtnBackgroundColor,
            backgroundHoverColor: plan.firstBtnBackgroundColorHover,
          },
          redirect: {
            redirect: plan.firstButtonRedirect,
            text: plan.firstBtnText,
            textColor: plan.firstBtnTextColor,
            textHoverColor: plan.firstBtnTextColorHover,
            borderColor: plan.firstBtnBorderColor,
            borderHoverColor: plan.firstBtnBorderColorHover,
            backgroundColor: plan.firstBtnBackgroundColor,
            backgroundHoverColor: plan.firstBtnBackgroundColorHover,
          },
          href: {
            href: plan.firstButtonHref,
            text: plan.firstBtnText,
            textColor: plan.firstBtnTextColor,
            textHoverColor: plan.firstBtnTextColorHover,
            borderColor: plan.firstBtnBorderColor,
            borderHoverColor: plan.firstBtnBorderColorHover,
            backgroundColor: plan.firstBtnBackgroundColor,
            backgroundHoverColor: plan.firstBtnBackgroundColorHover,
          },
          onClick: {
            onClick: plan.firstButtonOnClick,
            text: plan.firstBtnText,
            textColor: plan.firstBtnTextColor,
            textHoverColor: plan.firstBtnTextColorHover,
            borderColor: plan.firstBtnBorderColor,
            borderHoverColor: plan.firstBtnBorderColorHover,
            backgroundColor: plan.firstBtnBackgroundColor,
            backgroundHoverColor: plan.firstBtnBackgroundColorHover,
          },
        };
        const secondButtonMap = {
          scroolToId: {
            scrollToId: plan.secondButtonScrollToId,
            text: plan.secondBtnText,
            textColor: plan.secondBtnTextColor,
            textHoverColor: plan.secondBtnTextColorHover,
            borderColor: plan.secondBtnBorderColor,
            borderHoverColor: plan.secondBtnBorderColorHover,
            backgroundColor: plan.secondBtnBackgroundColor,
            backgroundHoverColor: plan.secondBtnBackgroundColorHover,
          },
          redirect: {
            redirect: plan.secondButtonRedirect,
            text: plan.secondBtnText,
            textColor: plan.secondBtnTextColor,
            textHoverColor: plan.secondBtnTextColorHover,
            borderColor: plan.secondBtnBorderColor,
            borderHoverColor: plan.secondBtnBorderColorHover,
            backgroundColor: plan.secondBtnBackgroundColor,
            backgroundHoverColor: plan.secondBtnBackgroundColorHover,
          },
          href: {
            href: plan.secondButtonHref,
            text: plan.secondBtnText,
            textColor: plan.secondBtnTextColor,
            textHoverColor: plan.secondBtnTextColorHover,
            borderColor: plan.secondBtnBorderColor,
            borderHoverColor: plan.secondBtnBorderColorHover,
            backgroundColor: plan.secondBtnBackgroundColor,
            backgroundHoverColor: plan.secondBtnBackgroundColorHover,
          },
          onClick: {
            onClick: plan.secondButtonOnClick,
            text: plan.secondBtnText,
            textColor: plan.secondBtnTextColor,
            textHoverColor: plan.secondBtnTextColorHover,
            borderColor: plan.secondBtnBorderColor,
            borderHoverColor: plan.secondBtnBorderColorHover,
            backgroundColor: plan.secondBtnBackgroundColor,
            backgroundHoverColor: plan.secondBtnBackgroundColorHover,
          },
        };
        return (
          <S.Wrapper key={plan}>
            {plan.personalizedService ? (
              <>
                <Typography type="h" level="4" color={plan.titleColor}>
                  {plan.title}
                </Typography>
                <S.TextWrapper>
                  <Typography type="p" level="1" margin="20px 0 30px 0">
                    {plan.userQuantity}
                  </Typography>
                </S.TextWrapper>
                <Button {...firstButtonMap[plan.firstButtonType]} largeText />
              </>
            ) : (
              <>
                <Typography
                  type="h"
                  level="4"
                  color={plan.titleColor}
                  fontWeight="600"
                >
                  {plan.title}
                </Typography>
                <Typography type="p" level="1" margin="20px 0 30px 0">
                  {plan.userQuantity}
                </Typography>
                <Button {...firstButtonMap[plan.firstButtonType]} />
                <S.PlanWrapper noBorder={!plan.secondBtnType}>
                  <ul>
                    {plan.listOfAdvantages.list.map(list => (
                      <S.ListIcon
                        key={list}
                        listColor={plan.listOfAdvantages.checkColor}
                      >
                        <p>{list}</p>
                      </S.ListIcon>
                    ))}
                  </ul>
                </S.PlanWrapper>
                {plan.secondBtnType && (
                  <S.PlanWrapper noBorder>
                    <Button {...secondButtonMap[plan.secondButtonType]} />
                  </S.PlanWrapper>
                )}
              </>
            )}
          </S.Wrapper>
        );
      })}
  </Carousel>
);

PlansMobile.defaultProps = {
  plans: [],
};

PlansMobile.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      personalizedService: PropTypes.bool,
      title: PropTypes.string,
      titleColor: PropTypes.string,
      userQuantity: PropTypes.string,
      firstBtnType: PropTypes.string,
      firstBtnText: PropTypes.string,
      firstBtnBackgroundColor: PropTypes.string,
      firstBtnBackgroundColorHover: PropTypes.string,
      firstBtnBorderColor: PropTypes.string,
      firstBtnBorderColorHover: PropTypes.string,
      firstBtnTextColor: PropTypes.string,
      firstBtnTextColorHover: PropTypes.string,
      firstButtonScrollToId: PropTypes.string,
      firstButtonRedirect: PropTypes.string,
      firstButtonHref: PropTypes.string,
      firstButtonOnClick: PropTypes.func,
      listOfAdvantages: {
        checkColor: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.string),
      },
      secondBtnType: PropTypes.string,
      secondBtnBackgroundColor: PropTypes.string,
      secondBtnBackgroundColorHover: PropTypes.string,
      secondBtnBorderColor: PropTypes.string,
      secondBtnBorderColorHover: PropTypes.string,
      secondBtnText: PropTypes.string,
      secondBtnTextColor: PropTypes.string,
      secondBtnTextColorHover: PropTypes.string,
      secondButtonScrollToId: PropTypes.string,
      secondButtonRedirect: PropTypes.string,
      secondButtonHref: PropTypes.string,
      secondButtonOnClick: PropTypes.func,
      secondValue: PropTypes.string,
    }),
  ),
};

export default PlansMobile;
