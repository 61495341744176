import styled from 'styled-components';
import { defaultTheme } from '../../styles/theme';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 0 100px 0;

  h4 {
    font-size: 25px;
  }

  @media (max-width: 768px) {
    h4 {
      text-align: center;
      font-size: 25px;
    }
  }
  @media (max-width: 540px) {
    h4 {
      font-size: 25px;
      line-height: 30px;
    }
  }
  @media (max-width: 418px) {
    padding: 40px 0;

    h4 {
      font-size: 20px;
      line-height: 30px;
    }
  }
  @media (max-width: 375px) {
    h4 {
      font-size: 17px;
      line-height: 25px;
    }
  }
  @media (max-width: 330px) {
    h4 {
      font-size: 14px;
      line-height: 25px;
    }
  }

  content-visibility: auto;
`;

export const TitleWrapper = styled.div`
  display: flex;
  > h4:nth-child(2) {
    color: ${({ spanColor }) => defaultTheme.typography[spanColor]};
  }
`;

export const ButtonWrapper = styled.div`
  padding: 50px 0 50px 0;
  width: 400px;
  p {
    font-size: 20px;
  }
  @media (max-width: 418px) {
    padding: 30px 0 30px 0;
    width: 100%;

    h4 {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;
