import styled from 'styled-components';
import GREEN_CHECK from '../../assets/icon/greenCheckIcon.png';
import { defaultTheme } from '../../styles/theme';

export const Container = styled.section`
  background-color: ${({ bgColor }) => defaultTheme.colors[bgColor]};
  min-height: 120%;
  max-height: 919px;
  padding: 5% 0;
  @media (max-width: 768px) {
    min-height: 110%;
    max-height: 120%;
  }

  content-visibility: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const Image = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 40%;
  @media (max-width: 1920px) {
    width: 70%;
  }
  @media (max-width: 1440px) {
    width: 50%;
  }
  @media (max-width: 1024px) {
    width: 100%;
    img {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileImage = styled.div`
  img {
    width: 100%;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;

export const Body = styled.div``;

export const List = styled.li`
  list-style: none;
  padding: 15px 0;
  width: 40%;
  position: relative;
  p {
    padding-left: 40px;
  }
  :after {
    content: '';
    background-image: url(${GREEN_CHECK});
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 30px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    left: 0px;
    top: 20px;
  }
  @media (max-width: 1920px) {
    min-width: 60%;
  }
  @media (max-width: 1440px) {
    width: 80%;
  }
  @media (max-width: 1366px) {
    width: 90%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 380px;
  p {
    font-size: 17px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
  }

  @media (max-width: 414px) {
    padding: 0;
    width: 200px;
    button {
      height: 40px;
      padding: 0;
    }
  }
`;
