import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { defaultTheme } from '../../styles/theme';

const fontFamilyType = {
  roboto: css`
    font-family: 'Roboto', sans-serif;
  `,
  montserrat: css`
    font-family: 'Montserrat', sans-serif;
  `,
};

const defaultWeightValues = {
  h1: 900,
  h2: 900,
  h3: 900,
  h4: 700,
  h5: 600,
  h6: 400,
  p1: 400,
  p2: 400,
  p3: 400,
};

const defaultSizeValues = {
  h1: '100px',
  h2: '70px',
  h3: '48px',
  h4: '48px',
  h5: '24px',
  h6: '20px',
  p1: '16px',
};

const fontSizeScreenValues = {
  h1: css`
    ${media.lessThan('1921px')`
      font-size: 9.8rem;
    `};
    ${media.lessThan('1681px')`
      font-size: 9.6rem;
    `};
    ${media.lessThan('1441px')`
      font-size: 9.4rem;
    `};
    ${media.lessThan('1367px')`
      font-size: 9.2rem;
    `};
    ${media.lessThan('1281px')`
      font-size: 9rem;
    `};
  `,
  h2: css`
    font-size: 60px;
    ${media.lessThan('1025px')`
      font-size: 50px;
    `};
    ${media.lessThan('769px')`
      font-size: 40px;
    `};
    ${media.lessThan('331px')`
      font-size: 30px;
    `};
  `,
  h3: css`
    ${media.lessThan('1921px')`
      font-size: 4.6rem;
    `};
    ${media.lessThan('1681px')`
      font-size: 4.4rem;
    `};
    ${media.lessThan('1441px')`
      font-size: 4.2rem;
    `};
    ${media.lessThan('1367px')`
      font-size: 4rem;
    `};
    ${media.lessThan('1281px')`
      font-size: 3.8rem;
    `};
  `,
  h4: css`
    font-size: 38px;
    ${media.lessThan('1025px')`
      font-size: 30px;
    `};
    ${media.lessThan('769px')`
      font-size: 25px;
    `};
    ${media.lessThan('331px')`
      font-size: 20px;
    `};
  `,
  h5: css`
    font-size: 24px;
    ${media.lessThan('1024px')`
      font-size: 20px;
    `};
    ${media.lessThan('450px')`
      font-size: 18px;
    `}
  `,
  h6: css`
    ${media.lessThan('1921px')`
      font-size: 1.8rem;
    `};
    ${media.lessThan('1681px')`
      font-size: 1.6rem;
    `};
    ${media.lessThan('1441px')`
      font-size: 1.5rem;
    `};
    ${media.lessThan('1367px')`
      font-size: 1.4rem;
    `};
    ${media.lessThan('1281px')`
      font-size: 1.3rem;
    `};
  `,
};

export const Heading = styled('h1').attrs(({ level }) => ({
  as: `h${level}`,
}))`
  ${({
    level,
    margin,
    padding,
    fontSize,
    fontWeight,
    letterSpacing,
    color,
    opacity,
    background,
    textAlign,
  }) => css`
    text-align: ${textAlign || 'left'};
    margin: ${margin || '0'};
    padding: ${padding || '0'};
    font-size: ${fontSize || defaultSizeValues[`h${level}`]};
    font-weight: ${fontWeight || defaultWeightValues[`h${level}`]};
    letter-spacing: ${letterSpacing || 'none'};
    opacity: ${opacity || '1'};
    color: ${defaultTheme.typography[color] || defaultTheme.typography.primary};
    background-color: ${defaultTheme.colors[background] ||
    defaultTheme.colors.none};
  `}
  ${({ type, level }) => fontSizeScreenValues[`${type}${level}`]};
  ${({ fontFamily }) => fontFamily && fontFamilyType[fontFamily]};
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      align-items: flex-end;
    `}

  > strong {
    font-weight: bold;
  }
`;

export const Paragraph = styled.p`
  ${({
    level,
    margin,
    padding,
    fontSize,
    fontWeight,
    letterSpacing,
    opacity,
    color,
    background,
    textAlign,
  }) => css`
    text-align: ${textAlign || 'left'};
    margin: ${margin || '0'};
    padding: ${padding || '0'};
    font-size: ${fontSize || defaultSizeValues[`p${level}`]};
    font-weight: ${fontWeight || defaultWeightValues[`p${level}`]};
    opacity: ${opacity || '1'};
    letter-spacing: ${letterSpacing || 'none'};
    color: ${defaultTheme.typography[color] || defaultTheme.typography.primary};
    background-color: ${defaultTheme.colors[background] ||
    defaultTheme.colors.none};
  `}
  ${({ fontFamily }) => fontFamily && fontFamilyType[fontFamily]};
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      align-items: flex-end;
    `}
  > strong {
    font-weight: bold;
  }
`;
