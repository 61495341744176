import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from '../../providers/snackbar';
import getUtms from '../../utils/functions/getUtms';
import LeadFormSchema from '../../validations/LeadFormSchema';
import Input from '../Input';
import Layout from '../Layout';
import Typography from '../Typography';
import * as S from './styled';

const LeadFormSession = ({
  logoAlt,
  logoSrc,
  text,
  titleForm,
  contactPage,
}) => {
  const { showSnackbar } = useSnackbar();
  const [utm, setUtm] = useState({
    term: '',
    source: '',
    medium: '',
    campaign: '',
    content: '',
  });

  const location = useLocation();
  const [isSending, setIsSending] = useState(false);

  const businessModelSelec = [
    { value: '', name: 'Modelo de Negócio' },
    { value: 'B2C', name: 'B2C' },
    { value: 'B2B', name: 'B2B' },
    { value: 'B2B2C', name: 'B2B2C' },
  ];

  const jobRoleSelect = [
    { value: '', name: 'Selecione' },
    {
      value: 'Head / Diretor / C-level de vendas',
      name: 'Head / Diretor / C-level de vendas',
    },
    {
      value: 'Head / Diretor / C-level de Marketing',
      name: 'Head / Diretor / C-level de Marketing',
    },
    {
      value: 'Coordenador / Gerente comercial ou de vendas',
      name: 'Coordenador / Gerente comercial ou de vendas',
    },
    {
      value: 'Coordenador / Gerente de Marketing',
      name: 'Coordenador / Gerente de Marketing',
    },
    {
      value: 'Supervisor comercial / de vendas',
      name: 'Supervisor comercial / de vendas',
    },
    { value: 'Supervisor de Marketing', name: 'Supervisor de Marketing' },

    { value: 'Analista de Marketing', name: 'Analista de Marketing' },
    {
      value: 'Analista comercial  / vendas',
      name: 'Analista comercial  / vendas',
    },
    { value: 'Estudante/ Estagiário', name: 'Estudante/ Estagiário' },
  ];

  const employeeSelect = [
    { value: '', name: 'Selecione' },
    { value: '1-10', name: '1-10' },
    { value: '11-50', name: '11-50' },
    { value: '51-200', name: '51-200' },
    { value: '201-500', name: '201-500' },
    { value: '501-1.000', name: '501-1.000' },
    { value: 'Acima de 1.000', name: 'Acima de 1.000' },
  ];

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(LeadFormSchema),
  });
  const onSubmit = useCallback(
    async data => {
      const params = {};

      try {
        if (typeof window !== 'undefined') {
          const rdData = [
            { name: 'identificador', value: 'wedash-contato' },
            {
              name: 'token_rdstation',
              value: 'd6b1a3de54dc354a8ff2e592ceaef3b4',
            },
            { name: 'privacy_data[communications]', value: '1' },
            { name: 'name', value: data.fullName },
            { name: 'email', value: data.email },
            { name: 'telefone', value: data.phone },
            { name: 'empresa', value: data.company },
            { name: 'Número de Funcionários', value: data.numberOfEmployees },
            { name: 'Cargo - Novo', value: data.jobRole },
            { name: 'leads-modelo-negocio', value: data.businessModel },
            { name: 'website', value: '' },
            { name: 'utm_term', value: utm.term },
            { name: 'utm_content', value: utm.content },
            { name: 'utm_campaign', value: utm.campaign },
            { name: 'utm_source', value: utm.source },
            { name: 'utm_medium', value: utm.medium },
            { name: 'url_pagina', value: location.href },
            { name: 'traffic_source', value: utm.source },
            { name: 'traffic_medium', value: utm.medium },
            { name: 'traffic_campaign', value: utm.campaign },
            { name: 'traffic_value', value: utm.term },
          ];

          window.RdIntegration.post(rdData);
          setIsSending(true);
        }

        setTimeout(() => {
          navigate('/thankspage/');
        }, 250);

        return params;
      } catch {
        showSnackbar({
          type: 'error',
          message: 'Não foi possivel enviar o formulário',
        });
        return null;
      }
    },
    [
      location.href,
      showSnackbar,
      utm.campaign,
      utm.content,
      utm.medium,
      utm.source,
      utm.term,
    ],
  );

  useEffect(() => {
    const url = location.href;
    setUtm(prevState => {
      return {
        ...prevState,
        term: getUtms('utm_term', url) || 'lp-site',
        source: getUtms('utm_source', url) || 'direct',
        medium: getUtms('utm_medium', url) || 'organic',
        campaign: getUtms('utm_campaign', url) || 'organic',
        content: getUtms('utm_content', url) || 'form',
      };
    });
  }, [location.href]);

  return (
    <>
      <Layout>
        <S.Container>
          {!contactPage && (
            <S.TextWrapper>
              <img
                src={logoSrc}
                alt={logoAlt}
                loading="lazy"
                decoding="async"
              />
              {!!text &&
                text.map(box => {
                  return (
                    <S.TextBox key={box}>
                      {box.map(element => {
                        return (
                          <Typography type="p" level="1" key={element}>
                            {element}
                          </Typography>
                        );
                      })}
                    </S.TextBox>
                  );
                })}
            </S.TextWrapper>
          )}
          <S.FormBody onSubmit={handleSubmit(onSubmit)}>
            <S.TitleForm>
              {!!titleForm &&
                titleForm.map(title => (
                  <Typography
                    type="p"
                    level="1"
                    fontWeight="500"
                    lineHeight="30px"
                    key={title}
                  >
                    {title}
                  </Typography>
                ))}
            </S.TitleForm>
            <input type="hidden" name="utm_term" value={utm.term} />
            <input type="hidden" name="utm_source" value={utm.source} />
            <input type="hidden" name="utm_medium" value={utm.medium} />
            <input type="hidden" name="utm_campaign" value={utm.campaign} />
            <input type="hidden" name="utm_content" value={utm.content} />
            <S.FormLine>
              <Input
                type="name"
                errors={errors.fullName?.message}
                label="Nome*"
                name="fullName"
                control={control}
              />
              <Input
                type="phone"
                errors={errors.phone?.message}
                label="Telefone*"
                name="phone"
                control={control}
              />
            </S.FormLine>
            <S.FormLine>
              <Input
                type="text"
                errors={errors.email?.message}
                label="E-mail Corporativo*"
                name="email"
                control={control}
              />
              <Input
                type="text"
                errors={errors.company?.message}
                label="Empresa*"
                name="company"
                control={control}
              />
            </S.FormLine>
            <S.FormLine>
              <Input
                type="select"
                errors={errors.numberOfEmployees?.message}
                label="Funcionários*"
                name="numberOfEmployees"
                options={employeeSelect}
                control={control}
              />
              <Input
                type="select"
                name="jobRole"
                options={jobRoleSelect}
                control={control}
                errors={errors.jobRole?.message}
                label="Seu cargo*"
              />
            </S.FormLine>
            <S.FormLine>
              <Input
                type="select"
                name="businessModel"
                options={businessModelSelec}
                control={control}
                errors={errors.businessModel?.message}
                label="Modelo de Negócio*"
              />
              <Input
                type="text"
                name="website"
                control={control}
                errors={errors.website?.message}
                label="Site da empresa*"
              />
            </S.FormLine>
            <S.FormLine max>
              <Input
                type="checkbox"
                name="iAgree"
                errors={errors.iAgree?.message}
                control={control}
                label="Nós cuidamos dos seus dados. Ao preencher o formulário você concorda em receber informações para tomar decisões baseadas em dados*"
              />
            </S.FormLine>
            <S.ButtonWrapper>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSending}
              >
                Fale com um especialista
              </Button>
            </S.ButtonWrapper>
          </S.FormBody>
        </S.Container>
      </Layout>
    </>
  );
};

LeadFormSession.defaultProps = {
  logoAlt: '',
  logoSrc: null,
  text: [],
  titleForm: [],
  contactPage: false,
};

LeadFormSession.propTypes = {
  logoAlt: PropTypes.string,
  logoSrc: PropTypes.node,
  text: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  titleForm: PropTypes.arrayOf(PropTypes.string),
  contactPage: PropTypes.bool,
};

export default LeadFormSession;
