export const defaultTheme = {
  colors: {
    transparent: 'transparent',
    primary: '#4A45D8',
    secondary: '#858EF3',
    third: '#222137',
    fourth: '#4BC0C0',
    seventh: '#E7F6F6',
    eighth: '#F6F6FD',
    white: '#FFFFFF',
    red: '#f5284c',
    violet: '#4A45D8',
    brown: '#848484',
    darkBrown: '#373021',
  },
  font: {
    family: 'Roboto',
  },
  alert: {
    error: '#F44336',
    warning: '#FF9800',
    info: '#2196F3',
    success: '#4CAF50',
  },
  typography: {
    transparent: 'transparent',
    primary: '#000000',
    secondary: '#FFFFFF',
    third: '#4A45D8',
    fourth: '#858EF3',
    sixth: '#4BC0C0',
    seventh: '#222137',
    red: '#f5284c',
    brown: '#848484',
    darkBrown: '#373021',
  },
};
