import styled from 'styled-components';
import media from 'styled-media-query';
import ReactPlayer from 'react-player';

export const Video = styled(ReactPlayer)`
  min-width: 1216px;
  min-height: 795px;
  ${media.lessThan('1921px')`
    min-width: 1016px;
    min-height: 595px;
  `}
`;
