import StyledBox from '@material-ui/core/Box';
import StyledAniLink from 'gatsby-plugin-transition-link/AniLink';
import styled, { css, keyframes } from 'styled-components';
import { defaultTheme } from '../../styles/theme';

const hambuerMenuAnimate = keyframes`
  0% {
    left: -100%;
    opacity: 50%;
  }
  50% {
    left: -50%;
    opacity: 70%;
  }
  100% {
    left: 0%;
    opacity: 100%;
  }
`;

export const WebContainer = styled(StyledBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;

  @media (max-width: 1200px) {
    padding: 0;
    display: none;
  }
`;

export const MobileNav = styled(StyledBox)`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 !important;
  }
`;

export const MobileMenuNav = styled(StyledBox)`
  display: none;

  @media (max-width: 1200px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Img = styled.img`
  ${({ invert }) =>
    invert &&
    css`
      filter: contrast(0) brightness(100);
    `}
  width: 220px;
  user-select: unset;
  @media (max-width: 500px) {
    width: 120px;
  }
`;

export const HamburguerMenu = styled.img`
  height: 40px;
  cursor: pointer;
  user-select: unset;
  filter: invert(30%) sepia(36%) saturate(4086%) hue-rotate(231deg)
    brightness(84%) contrast(102%);
  @media (max-width: 500px) {
    width: 30px;
  }
`;
export const CloseHamburguerMenu = styled.img`
  margin-top: 20px;
  height: 15px;
  cursor: pointer;
  user-select: unset;
  filter: invert();
`;

export const MobileContainer = styled(StyledBox)`
  display: none;
  ${({ mobileState }) =>
    !!mobileState &&
    css`
    display: block !important;
    animation ${hambuerMenuAnimate} 0.7s linear;
    background-color: ${defaultTheme.colors.third};
  `}
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 3rem;
  }
  @media (max-width: 1200px) {
    height: 80vh;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    > * + * {
      margin: 0;
    }
  }
`;

export const NavLink = styled.div`
  margin: 0 0 0 3rem;
  font-size: 18px;
  font-family: ${defaultTheme.font.family};
  transition: all opacity 0.7s ease-in;
  cursor: pointer;
  user-select: unset;
  color: rgba(0, 0, 0, 0.8);
  :hover {
    opacity: 0.8;
    transition: all opacity 0.7s ease-in;
  }
  @media (max-width: 1200px) {
    margin: 0;
    filter: invert();
  }
`;

export const AniLink = styled(StyledAniLink)`
  margin: 0;
  font-family: ${defaultTheme.font.family};
  cursor: pointer;
  user-select: unset;
  font-size: 18px;
  transition: all opacity 0.7s ease-in;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
  :hover {
    opacity: 0.8;
    transition: all opacity 0.7s ease-in;
  }
`;

export const NavButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: ${defaultTheme.colors.transparent};
  border: 2px solid ${defaultTheme.colors.primary};
  color: ${defaultTheme.colors.primary};
  font-family: ${defaultTheme.font.family};
  cursor: pointer;
  user-select: unset;
  outline: none;
  font-size: 18px;
  padding: 5px 10px;
  width: 180px;
  height: 40px;
  border-radius: 20px;
  transition: all opacity 0.7s ease-in;
  :hover {
    opacity: 0.8;
    transition: all opacity 0.7s ease-in;
  }
  @media (max-width: 1024px) {
    filter: contrast(0) brightness(100);
  }
`;
