import scrollTo from 'gatsby-plugin-smoothscroll';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button';
import Carousel from '../Carousel';
import Divisor from '../Divisor';
import Layout from '../Layout';
import Typography from '../Typography';
import Video from '../Video';
import * as S from './styled';

const KnowSession = ({
  videoTitle,
  video,
  videoAutoPlay,
  videoLoop,
  videoControls,
  videoLightMode,
  title,
  differentials,
  btnText,
  btnTextColor,
  btnTextColorHover,
  btnBackgroundColor,
  btnBackgroundColorHover,
  btnBorderColor,
  btnBorderColorHover,
  redirect,
  contactPage,
  ...props
}) => {
  const LIMITED_SIZE = 4;
  const splicedDifferentials = [];
  for (
    let accumulator = 0;
    accumulator < differentials.length;
    accumulator += LIMITED_SIZE
  ) {
    const insideArray = differentials.slice(
      accumulator,
      accumulator + LIMITED_SIZE,
    );
    splicedDifferentials.push(insideArray);
  }

  const handleScroll = () => scrollTo('#lead-form');

  const idForSection = video !== '' ? null : { id: 'diferenciais' };

  return (
    <Layout withoutPadding>
      {!!video && (
        <S.Container id="diferenciais">
          <>
            <S.Header>
              <Typography type="h" level="3" fontWeight="600" margin="0 20px">
                {videoTitle}
              </Typography>
              <Divisor margin="30px 0 70px 20px " width="55px" height="5px" />
            </S.Header>
            <S.VideoWrapper>
              <Video
                url={video}
                autoPlay={videoAutoPlay}
                loop={videoLoop}
                controls={videoControls}
                lightMode={videoLightMode}
              />
            </S.VideoWrapper>
          </>
        </S.Container>
      )}
      <S.Container haveVideo={video !== ''} {...idForSection}>
        <S.Title>{title}</S.Title>
        <S.Divider />
        <S.Wrapper>
          {splicedDifferentials.map(line => {
            return (
              <S.IconsLine key={line}>
                {line.map(item => {
                  return (
                    <S.IconWrapper key={item}>
                      <S.Image>
                        <img
                          src={item.imgSrc}
                          alt={item.imgAlt}
                          loading="lazy"
                          decoding="async"
                        />
                      </S.Image>
                      <S.TextWrapper>
                        <Typography type="p" level="1" fontWeight="500">
                          {item.title}
                        </Typography>
                        <Typography Typography type="p" level="1">
                          {item.context}
                        </Typography>
                      </S.TextWrapper>
                    </S.IconWrapper>
                  );
                })}
              </S.IconsLine>
            );
          })}
        </S.Wrapper>
        <S.MobileWrapper>
          <S.IconsLine>
            <Carousel>
              {differentials.map(item => {
                return (
                  <S.IconWrapper mobile key={item}>
                    <S.Image>
                      <img
                        src={item.imgSrc}
                        alt={item.imgAlt}
                        loading="lazy"
                        decoding="async"
                      />
                    </S.Image>
                    <S.TextWrapper>
                      <Typography type="p" level="1" fontWeight="500">
                        {item.title}
                      </Typography>
                      <Typography Typography type="p" level="1">
                        {item.context}
                      </Typography>
                    </S.TextWrapper>
                  </S.IconWrapper>
                );
              })}
            </Carousel>
          </S.IconsLine>
        </S.MobileWrapper>

        <S.ButtonContainer>
          <div>
            {contactPage ? (
              <Button
                text={btnText}
                textColor={btnTextColor}
                textHoverColor={btnTextColorHover}
                backgroundColor={btnBackgroundColor}
                backgroundHoverColor={btnBackgroundColorHover}
                borderColor={btnBorderColor}
                borderHoverColor={btnBorderColorHover}
                onClick={handleScroll}
                fullWidth
                {...props}
              />
            ) : (
              <Button
                text={btnText}
                textColor={btnTextColor}
                textHoverColor={btnTextColorHover}
                backgroundColor={btnBackgroundColor}
                backgroundHoverColor={btnBackgroundColorHover}
                borderColor={btnBorderColor}
                borderHoverColor={btnBorderColorHover}
                redirect={redirect}
                fullWidth
                {...props}
              />
            )}
          </div>
        </S.ButtonContainer>
      </S.Container>
    </Layout>
  );
};

KnowSession.defaultProps = {
  videoTitle: `Your videoTitle here`,
  video: '',
  videoAutoPlay: false,
  videoLoop: false,
  videoControls: true,
  videoLightMode: false,
  title: 'Your title here',
  differentials: [],
  btnText: 'Your text',
  btnTextColor: 'secondary',
  btnTextColorHover: 'secondary',
  btnBackgroundColor: 'primary',
  btnBackgroundColorHover: 'primary',
  btnBorderColor: 'primary',
  btnBorderColorHover: 'primary',
  redirect: '',
  contactPage: false,
};

KnowSession.propTypes = {
  videoTitle: PropTypes.string,
  video: PropTypes.string,
  videoAutoPlay: PropTypes.bool,
  videoLoop: PropTypes.bool,
  videoControls: PropTypes.bool,
  videoLightMode: PropTypes.bool,
  title: PropTypes.string,
  differentials: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.node,
      imgAlt: PropTypes.string,
      title: PropTypes.string,
      context: PropTypes.string,
    }),
  ),
  btnText: PropTypes.string,
  btnTextColor: PropTypes.string,
  btnTextColorHover: PropTypes.string,
  btnBackgroundColor: PropTypes.string,
  btnBackgroundColorHover: PropTypes.string,
  btnBorderColor: PropTypes.string,
  btnBorderColorHover: PropTypes.string,
  redirect: PropTypes.string,
  contactPage: PropTypes.bool,
};

export default KnowSession;
