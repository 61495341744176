import styled, { css } from 'styled-components';
import CheckIcon from '../../assets/icon/checkIcon.svg';
import { defaultTheme } from '../../styles/theme';

const customPadding = css`
  padding: ${({ plansPadding }) => plansPadding || '0px'};
`;

export const Container = styled.section`
  display: flex;
  align-items: flex-start;
  ${customPadding};

  @media (max-width: 1124px) {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  width: 15.625rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 25px;
  width: 25%;

  @media (max-width: 1124px) {
    width: 100%;
    margin: 0 10px 5px;
    border-radius: 20px;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 6%),
      0px 2px 2px 0px rgb(0 0 0 / 6%), 3px 3px 6px 0px rgb(0 0 0 / 6%);
  }

  @media (max-width: 768px) {
    margin: 0 7.5px 5px;
  }
  @media (max-width: 320px) {
    margin: 0 5px;
    padding: 5px;
  }
`;

export const TextWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > p,
  h5 {
    text-align: center;
  }
`;

export const PlanWrapper = styled.div`
  ${({ noBorder }) =>
    noBorder
      ? css`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px 25px 20px;
          text-align: center;
        `
      : css`
          width: 100%;
          padding-bottom: 40px;
          display: flex;
          align-items: center;
          text-align: center;
          padding: 0 25px;
          border: 1px solid RGBA(0, 0, 0, 0.1);
          border-left: none;
          border-right: none;
          border-top: none;

          > ul {
            margin: 10px 0 30px 20px;
          }
        `}
`;

export const ListIcon = styled.li`
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  ${({ noIcon }) =>
    !noIcon &&
    css`
      position: relative;
      margin: 15px 0;
      :after {
        content: '';
        position: absolute;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        left: 0px;
        background-color: ${({ listColor }) => defaultTheme.colors[listColor]};
      }
      :before {
        content: '';
        background-image: url(${CheckIcon});
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 7px;
        height: 7px;
        left: 4px;
        z-index: 2;
      }
    `}
  p {
    text-align: left;
    padding-left: 30px;
  }
`;
